import { ChevronLeft } from '@mui/icons-material'
import './_JustCloseToolBar.scss'
import DoneIcon from '@mui/icons-material/Done'
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material'

interface JustCloseToolBarProps {
    title: string
    onCloseNoSave: () => void
    handleSubmit: any
}

const JustCloseToolBar = (props: JustCloseToolBarProps) => {
    const { title, onCloseNoSave, handleSubmit } = props

    return (
        <AppBar position="static">
            <Toolbar>
                <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={onCloseNoSave}
                    sx={{ mr: 2 }}
                >
                    <ChevronLeft />
                </IconButton>
                {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}> */}
                <Typography variant="h6" component="h3">
                    {title}
                </Typography>
                <div>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleSubmit}
                        color="inherit"
                    >
                        <DoneIcon />
                    </IconButton>
                </div>
            </Toolbar>
        </AppBar>
    )
}
export default JustCloseToolBar
