import './_CareForm.scss'
import { ICare } from '../ICare'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly'
import Tab from '@mui/material/Tab'
import { Operation } from '../../../../Common/Types/CommonType'
import CareFormDetails from './Details/CareFormDetails'
import { useForm } from 'react-hook-form'
import React from 'react'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import CareFormat from '../List/ListItemTextAttributes'
import { useAuth } from '../../../../../contexts/AuthContext'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import FormLayout from '../../../../../layouts/FormLayout/FormLayout'
import CommonTabContainer from '../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../Common/CommonTabHeader/CommonTabHeader'
import { Container } from '@mui/material'
import CommonTabIcon from '../../../../Common/CommonTabIcon/CommonTabIcon'

const CareForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const { user } = useAuth()
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const methods = useForm<ICare>({ defaultValues: item })
    const { control, setValue, watch } = methods
    const [tabValue, setTabValue] = useState('1')
    const [counters, setCounters] = useState<{ [key: string]: string }>({ students: '0' })

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    const hanleCountersLoaded = (countersData: { [key: string]: string }) => {
        setCounters(prevCounters => ({
            ...prevCounters,
            ...countersData,
        }))
    }
    const urls = ['cares/' + item.CareID + '/students?count=1']

    return (
        <FormLayout
            countersURLs={urls}
            operation={operation}
            mode={mode}
            item={item}
            onClose={onClose}
            onCloseNoSave={onCloseNoSave}
            onCountersLoaded={hanleCountersLoaded}
            formLabelName="Care"
            formIDColName="CareID"
            formNameColName="Name"
            formAPI="cares"
            methods={methods}
            tabValue={tabValue}
        >
            <CommonTabHeader handleTabChange={handleTabChange}>
                <Tab
                    label={t('Details') + ' (' + counters.students + ' ' + t('StudentCount') + ')'}
                    value="1"
                    icon={<InfoIcon />}
                />
                <Tab
                    label={t('Cares')}
                    value="2"
                    icon={<CommonTabIcon icon={<ChildFriendlyIcon />} counter={parseInt(counters.cares)} />}
                />
            </CommonTabHeader>

            <CommonTabContainer displayTab={'1' === tabValue}>
                <Container>
                    <CareFormDetails
                        mode={mode}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        item={item}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                </Container>
            </CommonTabContainer>

            <CommonTabContainer displayTab={'2' === tabValue}>
                <CommonInfiniteScrollList
                    url={'organizations/' + user!.OrganizationID + '/cares'}
                    isVisibleNow={tabValue === '2'}
                    ListItemTextAttributes={CareFormat}
                    CRUDEForm={CareForm}
                    hideAddition={true}
                    CRUDEFormURL="care"
                    hideMultiSelect={true}
                />
            </CommonTabContainer>
        </FormLayout>
    )
}

export default CareForm
