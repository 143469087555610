//import './_Login.scss'

import { Button, Grid, Box, Typography, Link, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { ILogin } from './ILogin'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { useAuth } from '../../../../contexts/AuthContext'
import { GoogleLogin, CredentialResponse } from '@react-oauth/google'
import { jwtDecode } from 'jwt-decode'
import { useEffect, useRef, useState } from 'react'
import Onboarding from '../../../../layouts/Onboarding/Onboarding'
import UFCheckBox from '../../../Common/UseForm/UFCheckBox/UFCheckBox'
import { useAlert } from '../../../../contexts/AlertContext'
import { useLoading } from '../../../../contexts/LoadingContext'

const Login = () => {
    const { login, loginGoogle } = useAuth()
    const [Is2FA, setIs2FA] = useState(0)
    const { setLoading } = useLoading()
    const { t, i18n } = useTranslation()
    const methods = useForm<ILogin>({
        defaultValues: { email: '', password: '', twofactorcode: '', googleAuthCode: '', googlePicture: '' },
    })
    const { handleSubmit, control, setValue, resetField, watch } = methods

    const emailValue = watch('email')

    const [buttonWidth, setButtonWidth] = useState(400)
    const elementRef = useRef<HTMLButtonElement>(null)
    const [isAuthReady, setIsAuthReady] = useState(false)
    const { setAlert } = useAlert()

    useEffect(() => {
        if (elementRef.current) {
            const elementWidth = elementRef.current.getBoundingClientRect().width
            setButtonWidth(elementWidth)
        }
    }, [])

    useEffect(() => {
        if (emailValue === '') {
            resetField('password')
        }
    }, [emailValue, resetField])

    const handleLogin = async (prm: ILogin) => {
        const prmLogin: ILogin = {
            email: prm.email,
            password: prm.password,
            twofactorcode: prm.twofactorcode,
            googleAuthCode: '',
            googlePicture: '',
            keepLoggedIn: typeof prm.keepLoggedIn === 'undefined' ? false : prm.keepLoggedIn,
        }
        login(prmLogin.email, prmLogin.password, prmLogin.twofactorcode, prmLogin.googleAuthCode, prmLogin.keepLoggedIn)
    }

    const handleResetIs2FA = () => {
        setIs2FA(0)
    }

    const handleGoogleSuccess = (credentialResponse: CredentialResponse) => {
        if (credentialResponse && credentialResponse.credential) {
            const decodedToken = jwtDecode<any>(credentialResponse.credential)

            // Now you have the user's details
            console.log('User Picture:', decodedToken.picture)

            const tmpKeepMeLoggedIn = watch('keepLoggedIn')
            const prmLogin: ILogin = {
                email: decodedToken.email,
                password: '',
                twofactorcode: '',
                googleAuthCode: credentialResponse.credential,
                googlePicture: decodedToken.picture,
                keepLoggedIn: typeof tmpKeepMeLoggedIn === 'undefined' ? false : tmpKeepMeLoggedIn,
            }

            loginGoogle(
                prmLogin.email,
                prmLogin.password,
                prmLogin.twofactorcode,
                prmLogin.googleAuthCode,
                prmLogin.keepLoggedIn
            )
        }
        setLoading(false)
    }

    const handleGoogleError = () => {
        console.error('Login failed')
        setLoading(false)
    }

    {
        /*if (isAuthReady) {
        // Optionally render a loading indicator until the socket is ready
        return <div>{t('Accessing Dashboard...')}</div>
    }*/
    }

    const handleError = (errors: any) => {
        setAlert({
            message: t('Error'),
            severity: 'error',
            datetime: Date.now().toString(),
        })
    }

    return (
        <>
            <Onboarding>
                <Typography component="h1" variant="h5" align="center" sx={{ mb: 2 }}>
                    {Is2FA ? t('We have sent to you a 2FA code, please provide it!') : t('Welcome')}
                </Typography>

                <GoogleLogin
                    onSuccess={(response: any) => {
                        setLoading(true)
                        handleGoogleSuccess(response)
                    }}
                    onError={() => {
                        setLoading(true)
                        handleGoogleError()
                    }}
                    locale={i18n.language}
                    size="large"
                    width={buttonWidth}
                />

                <Divider sx={{ mt: 2, mb: 2 }}>
                    <Typography component="span" variant="body2" align="center" sx={{ mb: 2 }}>
                        {t('OR')}
                    </Typography>
                </Divider>
                <Box
                    id="fieldsContainerBox"
                    component="form"
                    onSubmit={handleSubmit(handleLogin, handleError)}
                    noValidate
                    sx={{ mt: 1 }}
                >
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="email"
                        label={t('Email')}
                        required={t('Empty field!')}
                        autoComplete={'email'}
                        autoFocus={true}
                        disabled={Is2FA === 1}
                        inputType={EInputType.Email}
                        customSx={{ m: 0 }}
                    />

                    {Is2FA === 0 && (
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="password"
                            label={t('Password')}
                            required={t('Empty field!')}
                            autoComplete={'current-password'}
                            autoFocus={true}
                            inputType={EInputType.Password}
                        />
                    )}

                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="twofactorcode"
                        label={t('2FA code')}
                        required={t('Empty field!')}
                        autoFocus={true}
                        inputType={EInputType.Numeric}
                        hidden={true && Is2FA === 0}
                    />

                    <Button
                        ref={elementRef}
                        data-test="signin-button"
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {Is2FA ? t('Provide 2FA Code!!') : t('Enter')}
                    </Button>

                    <UFCheckBox control={control} name="keepLoggedIn" label={t('Keep me logged in')} />
                    {Is2FA === 1 && (
                        <Typography variant="body2" color="textSecondary" align="center">
                            <Link underline="none" onClick={handleResetIs2FA} href="#">
                                {t('Start Login again')}
                            </Link>
                        </Typography>
                    )}
                    {Is2FA === 0 && (
                        <Grid container display="block" justifyContent="left">
                            <Grid sx={{ mb: 1 }}>
                                <Typography variant="body2" align="left">
                                    {t('Did you forget your password?')}{' '}
                                    <Link underline="hover" href="/forgotpassword" variant="inherit">
                                        {t('Forgot password ?')}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {Is2FA === 0 && (
                        <Grid container display="block" justifyContent="left">
                            <Grid sx={{ mb: 1 }}>
                                <Typography variant="body2" align="left">
                                    {t('Don´t have an account?')}{' '}
                                    <Link href="/signup" underline="hover" variant="inherit">
                                        {t('Request to your school Administrator')}
                                    </Link>
                                </Typography>
                            </Grid>
                            <Grid sx={{ mb: 1 }}>
                                <Typography variant="body2" align="left">
                                    {t('Do you want your school to join Eskola?')}{' '}
                                    <Link href="/signup" underline="hover" variant="inherit">
                                        {t('Register')}
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            </Onboarding>
        </>
    )
}
export default Login
