import './_FaultForm.scss'
import { IFault } from '../IFault'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Tab from '@mui/material/Tab'
import { Operation } from '../../../../Common/Types/CommonType'
import { useForm } from 'react-hook-form'
import React from 'react'
import FaultFormDetails from './Details/FaultFormDetails'
import FormLayout from '../../../../../layouts/FormLayout/FormLayout'
import CommonTabContainer from '../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../Common/CommonTabHeader/CommonTabHeader'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import { Container } from '@mui/material'

const FaultForm = (props: CommonFormProps) => {
    const { t } = useTranslation()

    const methods = useForm<IFault>({ defaultValues: props.item })
    const { control, setValue, watch } = methods
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [counters, setCounters] = useState<{ [key: string]: string }>({ students: '0', cares: '0' })
    const [tabValue, setTabValue] = useState('1')
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }
    const hanleCountersLoaded = (countersData: { [key: string]: string }) => {
        setCounters(prevCounters => ({
            ...prevCounters,
            ...countersData,
        }))
    }
    const urls = ['']

    return (
        <FormLayout
            countersURLs={urls}
            operation={operation}
            mode={mode}
            item={item}
            onClose={onClose}
            onCloseNoSave={onCloseNoSave}
            onCountersLoaded={hanleCountersLoaded}
            formLabelName="Fault"
            formIDColName="FaultID"
            formNameColName="Name"
            formAPI="faults"
            methods={methods}
            tabValue={tabValue}
        >
            <CommonTabHeader handleTabChange={handleTabChange}>
                <Tab label={t('Details')} value="1" icon={<InfoIcon />} />
            </CommonTabHeader>
            <CommonTabContainer displayTab={'1' === tabValue}>
                <Container>
                    <FaultFormDetails
                        mode={mode}
                        control={control}
                        watch={watch}
                        setValue={setValue}
                        item={item}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                </Container>
            </CommonTabContainer>
        </FormLayout>
    )
}

export default FaultForm
