import ListItemTextPrimary from '../../../../../layouts/List/ListItemTextPrimary'
import { ALLERGIESICON, CARESICON, MEALSICON, CLASSROOMSICON, CONTACTDETAILSICON } from '../../../../../styles/_icons'
import CustomSVG from '../../../../Common/CustomSVG/CustomSVG'
import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
interface ListItemTextAttributesProps {
    item: any
}

const ListItemTextAttributes: React.FC<ListItemTextAttributesProps> = ({ item }) => {
    const { t } = useTranslation()
    return (
        <div>
            <Typography variant="h6" component="h3"></Typography>
            <ListItemTextPrimary
                text={
                    (item.Name ? item.Name : '') +
                    ' ' +
                    (item.Surname1 ? item.Surname1 : '') +
                    ' ' +
                    (item.Surname2 ? item.Surname2 : '')
                }
            />

            <Typography component="ul" variant="body2" sx={{ paddingInlineStart: 0 }}>
                {Boolean(item.SpecialReqs) === true && (
                    <li className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={ALLERGIESICON} />
                        </div>
                        <span>{'See the special requirements'}</span>
                    </li>
                )}
                <li className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={CLASSROOMSICON} />
                    </div>
                    <span>{item.ClassRoomDSCR}</span>
                </li>
                {
                    <li className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={CARESICON} />
                        </div>
                        <span>
                            {item.CaresCount + ''} {t('Cares')}
                        </span>
                    </li>
                }
                {
                    <li className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={MEALSICON} />
                        </div>
                        <span>
                            {item.MealsCount + ''} {t('Meals')}{' '}
                        </span>
                    </li>
                }
                {
                    <li className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={CONTACTDETAILSICON} />
                        </div>

                        <div style={{ whiteSpace: 'pre-line' }}>
                            {item.ContactsDSCR === null || typeof item.ContactsDSCR === 'undefined'
                                ? ''
                                : item.ContactsDSCR}{' '}
                            {/*item.ContactsDSCR.replace(/,/g, '\n')}*/}
                        </div>
                    </li>
                }
            </Typography>
        </div>
    )
}

export default ListItemTextAttributes
