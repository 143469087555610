// import './_StudentCard.scss'
import { IStudent } from '../IStudent'
import { useTranslation } from 'react-i18next'
import CustomAvatar from '../../../../Common/CustomAvatar/CustomAvatar'
import CardDetails from '../../../../../layouts/CardDetails/CardDetails'
import ContactDetails from './ContactDetails/ContactDetails'
import ExtraDetails from './ExtraDetails/ExtraDetails'
import SchoolDetails from './SchoolDetails/SchoolDetails'
import NameDetails from './NameDetails/NameDetails'
import Profile from '../../../../../layouts/Profile/Profile'

interface ICardProps {
    item: IStudent
    handleEditCard: (prmType: string) => void
}

const StudentCard = (props: ICardProps) => {
    const { handleEditCard, item } = props
    const { t } = useTranslation()
    return (
        <>
            {/*Moved to Profile layout*/}
            {/*<CardDetails cardLabel="Name Details Information" cardType="namedetails" handleEditCard={handleEditCard}>
                <NameDetails item={item} />
    </CardDetails>*/}

            <CardDetails cardLabel="School Information" cardType="schooldetails" handleEditCard={handleEditCard}>
                <SchoolDetails item={item} />
            </CardDetails>

            <CardDetails cardLabel="Contact Details" cardType="contactdetails" handleEditCard={handleEditCard}>
                <ContactDetails item={item} />
            </CardDetails>

            <CardDetails cardLabel="Extra Information" cardType="extradetails" handleEditCard={handleEditCard}>
                <ExtraDetails item={item} />
            </CardDetails>
        </>
    )
}

export default StudentCard
