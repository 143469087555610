import './_CommonDashboardWidget.scss'
import { Grid, Paper, Stack, Typography } from '@mui/material'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import { motion } from 'framer-motion'
import { Methods } from '../../../Utils/Utils'
import CustomSVG from '../CustomSVG/CustomSVG'

import { useCurrentScreen } from '../../../contexts/CurrentScreenContext'

type CommonDashboardWidgetProps = {
    index: number
    name: string
    svgIcon: string
    route: string
    color: string
}

const CommonDashboardWidget: React.FC<CommonDashboardWidgetProps> = ({ index, name, svgIcon, route, color }) => {
    const { setCurrentPageName, setCurrentPageIcon } = useCurrentScreen()
    const navigate = useNavigate()
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        //event.preventDefault() // Prevent the default behavior if needed
        setCurrentPageName(name)
        setCurrentPageIcon(svgIcon)
        if (!event.defaultPrevented) {
            navigate('/' + route)
        }
    }
    return (
        <Stack
            sx={{
                width: '162px',
                height: '162px',
                // {

                //     // xs: '47.5%', // 100px width on extra small screens
                //     // sm: '47.5%', // 200px width on small screens
                //     // md: '18.8%',   // 300px width on medium screens
                //     // lg: '18.8%',   // 400px width on large screens
                //     // xl: '25%',   // 500px width on extra large screens
                // },
            }}
        >
            <Paper
                component={motion.div}
                whileHover={{ scale: 1.05 }}
                sx={{
                    padding: 2,
                    //backgroundColor: Methods.generateColor(index),
                    backgroundColor: typeof color !== 'undefined' ? color : 'var(--primary-light)',
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                }}
            >
                {/* We have to wrap the MUI Link with the react-router LINK because MUI Link does a full refresh of the site, it is ok for Login but not for these ones */}
                <MuiLink
                    component={RouterLink}
                    to={'/' + route}
                    onClick={handleClick}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                >
                    <Grid display="flex" justifyContent="center" alignItems="center" container direction="column">
                        <CustomSVG
                            strSVG={svgIcon}
                            customFontSize="large"
                            customSX={{ m: 1, color: 'var(--primary-main)' }}
                        />
                        <Typography
                            component="h2"
                            variant="body2"
                            gutterBottom
                            align="center"
                            sx={{ textTransform: 'uppercase' }}
                        >
                            {name}
                        </Typography>
                    </Grid>
                </MuiLink>
            </Paper>
        </Stack>
    )
}

export default CommonDashboardWidget
