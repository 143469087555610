// import './_Default.scss'
import { ReactNode, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Box, AppBar, Stack, Dialog, DialogContent, Typography, IconButton, Toolbar, Tooltip } from '@mui/material'
// import CssBaseline from '@mui/material/CssBaseline'
import { useTranslation } from 'react-i18next'

// import Toolbar from '@mui/material/Toolbar'
// import IconButton from '@mui/material/IconButton'
// import Typography from '@mui/material/Typography'
// import FileDownload from '@mui/icons-material/FileDownload'
import { ChevronLeft, Edit } from '@mui/icons-material'
// import SearchIcon from '@mui/icons-material/Search'
// import MoreIcon from '@mui/icons-material/MoreVert'
import AttachFile from '@mui/icons-material/AttachFile'
// import Edit from '@mui/icons-material/Edit'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CustomAvatar from '../../components/Common/CustomAvatar/CustomAvatar'
import CommonTransition from '../../components/Common/CommonTransition/CommonTransition'
import { TabContext } from '@mui/lab'
import React from 'react'
import { Methods } from '../../Utils/Utils'
import { useAlert } from '../../contexts/AlertContext'
import { useCurrentScreen } from '../../contexts/CurrentScreenContext'
import ActionForFormMode from '../../components/Common/MenuActions/ActionForFormMode/ActionForFormMode'

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
    // alignItems: 'flex-start',
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(2),
    // Override media queries injected by theme.mixins.toolbar
    // '@media all': {
    //     minHeight: 128,
    // },
}))

// const drawerWidth = 300

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' && prop !== 'openRight' })<{
    open?: boolean
    openRight?: boolean
}>(({ theme, open, openRight }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
}))

// const DrawerHeader = styled('div')(({ theme }) => ({
//     display: 'flex',
//     alignItems: 'center',
//     padding: theme.spacing(0, 1),
//     ...theme.mixins.toolbar,
//     justifyContent: 'flex-end',
// }))

interface profileProps {
    item: any
    children: ReactNode
    onCloseNoSave: () => void
    tabValue: string
    methods: any
    onClickEdit: (prmType: string) => void
    nameLabelValue: string
    subNameLabelValue: string
    IDColName: string
    avatarType: string
    onCountersLoaded: (counters: { [key: string]: string }) => void
    countersURLs: string[]
}

const Profile = (props: profileProps) => {
    const { t } = useTranslation()
    const { setAlert } = useAlert()
    const {
        children,
        onCloseNoSave,
        onClickEdit,
        tabValue,
        methods,
        item,
        nameLabelValue,
        subNameLabelValue,
        IDColName,
        avatarType,
        onCountersLoaded,
        countersURLs,
    } = props

    const {
        control,
        setValue,
        formState: { errors },
    } = methods

    const { popMode } = useCurrentScreen()

    useEffect(() => {
        const getCountersData = async () => {
            try {
                const countersData = await Methods.getCountersBulk(countersURLs)

                onCountersLoaded(countersData)
            } catch (error) {
                console.log(error)
                setAlert({
                    message: t('Error'),
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            }
        }
        //setMenuActionType('form')
        getCountersData()
    }, [])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            // Check if Ctrl (or Cmd) and S keys are pressed
            if ((event.ctrlKey || event.metaKey) && event.key === 'e') {
                event.preventDefault()
                onClickEdit('namedetails')
            }
        }

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown)

        // Clean up the event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    useEffect(() => {
        //setMenuActionType('form')
    }, [item])

    const handleCloseNoSave = () => {
        popMode()
        onCloseNoSave()
    }
    return (
        <>
            <Dialog open={true} onClose={handleCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
                <DialogContent sx={{ position: 'relative', width: '100%', height: '100vh', marginTop: '200px' }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="fixed" color="inherit">
                            <StyledToolbar>
                                {/* Back button */}
                                <IconButton
                                    size="large"
                                    edge="start"
                                    aria-label="open drawer"
                                    sx={{ mr: 2 }}
                                    onClick={handleCloseNoSave}
                                >
                                    <ChevronLeft />
                                </IconButton>
                                <Typography
                                    noWrap
                                    component="h1"
                                    className="pageTitle"
                                    sx={{ flexGrow: 1, alignSelf: 'flex-end', pt: 1, userSelect: 'none' }}
                                ></Typography>
                                <IconButton size="large" aria-label="edit">
                                    <NotificationsIcon />
                                </IconButton>
                                <ActionForFormMode />
                                <IconButton size="large" aria-label="edit" onClick={() => onClickEdit('namedetails')}>
                                    <Tooltip title={t('Edit') + ' ' + 'Ctrl/Cmd + E'}>
                                        <Edit />
                                    </Tooltip>
                                </IconButton>
                            </StyledToolbar>
                            <Stack direction="row" justifyContent="center" alignItems="center">
                                <CustomAvatar
                                    itemType={avatarType}
                                    keyName={IDColName}
                                    item={item}
                                    sizeHeight={100}
                                    sizeWidth={100}
                                    justViewMode={true}
                                />
                            </Stack>
                            {/* terminar avatar */}
                            <Typography variant="h5" component="h1" sx={{ flexGrow: 1, alignSelf: 'center', p: 1 }}>
                                {nameLabelValue}
                            </Typography>
                            <Typography
                                variant="h5"
                                noWrap
                                component="h1"
                                sx={{ flexGrow: 1, alignSelf: 'center', p: 1 }}
                            ></Typography>
                        </AppBar>
                    </Box>
                    <TabContext value={tabValue}>
                        {React.Children.map(children, child => {
                            if (React.isValidElement(child)) {
                                const propsToPass = { control, setValue, errors }
                                return React.cloneElement(child, propsToPass)
                            }
                            return child
                        })}
                    </TabContext>
                </DialogContent>
            </Dialog>
        </>
    )
}
export default Profile
