import { Methods } from '../../../../../../Utils/Utils'
import { LEVELSICON, ORGANIZATIONSICON } from '../../../../../../styles/_icons'
import CustomSVG from '../../../../../Common/CustomSVG/CustomSVG'
import { IStudent } from '../../IStudent'
import './_SchoolDetails.scss'

interface ISchoolDetailsProps {
    item: IStudent
}

const SchoolDetails = (props: ISchoolDetailsProps) => {
    const { item } = props
    return (
        <>
            <div className="icon-container">
                <div className="icon">
                    <CustomSVG strSVG={ORGANIZATIONSICON} />
                </div>
                {Methods.formatText(item.OrganizationDSCR, 'School')}
            </div>
            <div className="icon-container">
                <div className="icon">
                    <CustomSVG strSVG={LEVELSICON} />
                </div>
                <div>{Methods.formatText(item.LevelDSCR, 'Level')}</div>
            </div>
        </>
    )
}
export default SchoolDetails
