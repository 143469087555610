import React, { useState, useEffect } from 'react'
import { Autocomplete, TextField, CircularProgress, InputAdornment, IconButton } from '@mui/material'
import { PersonOutline, Search, Clear } from '@mui/icons-material'
import api from '../../../Utils/APIService'
import { Methods } from '../../../Utils/Utils'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { useCurrentScreen } from '../../../contexts/CurrentScreenContext'
import CustomSVG from '../CustomSVG/CustomSVG'

interface OptionType {
    id: number // Unique identifier
    label: string
}

interface PaginatedAsyncAutocompleteProps {
    handleFilterChange?: (queryWords: string) => void
    url: string
    name: string
    defaultValue?: OptionType
    control?: any
    setValue?: any
    componentLabel?: string
    disabled?: boolean
    required?: string
}

const PaginatedAsyncAutocomplete: React.FC<PaginatedAsyncAutocompleteProps> = (
    props: PaginatedAsyncAutocompleteProps
) => {
    const {
        handleFilterChange,
        url,
        defaultValue = { id: 0, label: '' },
        control,
        name,
        componentLabel,
        setValue,
        disabled = false,
        required = '',
    } = props
    const [open, setOpen] = useState<boolean>(false)
    //const [options, setOptions] = useState<OptionType[]>([])
    const [options, setOptions] = useState<any[]>([])
    const [selectedValue, setSelectedValue] = useState<any | null>(defaultValue.id)
    const [loading, setLoading] = useState<boolean>(false)
    const [page, setPage] = useState<number>(1)
    const [hasMore, setHasMore] = useState<boolean>(true)
    const [inputValue, setInputValue] = useState<string>(defaultValue.label)
    const { t } = useTranslation()
    const { currentPageIcon, currentPageName } = useCurrentScreen()
    // Simulate fetching paginated data asynchronously
    const fetchOptions = async (query: string, pageNumber: number) => {
        if (!hasMore) return

        setLoading(true)

        const itemsPerPage = 30
        const tmpURL =
            process.env.REACT_APP_METHODS_API + url + `?page=${pageNumber}&pageSize=${itemsPerPage}&filter=${query}`

        let fetchedData = await api.get(tmpURL)

        fetchedData = Methods.JSONUnminify(fetchedData.data)

        // Filter data based on user input
        if (Array.isArray(fetchedData)) {
            const filteredData = fetchedData.filter(item => {
                const fullName =
                    item.Name +
                    (typeof item.Surname1 === 'undefined' ? '' : ' ' + item.Surname1) +
                    (typeof item.Surname2 === 'undefined' ? '' : ' ' + item.Surname2)
                return fullName.toLowerCase().trim().includes(query.toLowerCase().trim())
            })
            console.log('filteredData:', filteredData.length.toString())
            const paginatedData = filteredData.slice((pageNumber - 1) * itemsPerPage, pageNumber * itemsPerPage)

            setOptions(prev => [...prev, ...paginatedData])
            setHasMore(paginatedData.length === itemsPerPage)
            setLoading(false)
        } else {
            console.log('No data in the array')
        }
    }

    useEffect(() => {
        if (!open) {
            setOptions([])
            setPage(1)
            setHasMore(true)
        }
    }, [open])

    //so in the rerender takes the value from the props

    // Trigger fetching more data when the user scrolls to the end of the options
    const handleScroll = (event: React.SyntheticEvent) => {
        const listboxNode = event.currentTarget as HTMLElement
        if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight && !loading && hasMore) {
            setPage(prevPage => prevPage + 1)
        }
    }

    // Fetch data when input or page changes
    useEffect(() => {
        if (inputValue && open) {
            fetchOptions(inputValue, page)
        } else {
            if (control && inputValue === '' && open) {
                fetchOptions('', page)
            }
        }
    }, [inputValue, page, open])

    const handleClear = () => {
        setInputValue('') // Clear the input value
        setSelectedValue(null)
        setOptions([]) // Clear the options as well
    }

    return control ? (
        <Controller
            name={name}
            control={control}
            defaultValue={selectedValue}
            rules={{
                required: required,
                validate: value => (value !== null && value !== '' ? true : required),
            }}
            render={({ fieldState }) => (
                <Autocomplete
                    disabled={disabled}
                    freeSolo={false}
                    clearIcon={inputValue ? <Clear /> : <Search />}
                    clearOnEscape
                    open={open}
                    value={selectedValue}
                    noOptionsText={t('No Values')}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onInputChange={(event: React.SyntheticEvent, value: string) => {
                        if (value === 'undefined') {
                            return
                        }
                        setInputValue(value)
                        setOptions([])
                        setPage(1)
                        setHasMore(true)
                        if (value === '' && handleFilterChange) {
                            handleFilterChange('')
                        }
                    }}
                    onChange={(event: React.SyntheticEvent, item: any | null) => {
                        setSelectedValue(item)
                        if (item != null) {
                            setValue(name, item[Object.keys(item)[0]])
                        } else {
                            setValue(name, '')
                        }
                        if (item && handleFilterChange) {
                            handleFilterChange(
                                item.Name +
                                    (typeof item.Surname1 === 'undefined' ? '' : ' ' + item.Surname1) +
                                    (typeof item.Surname2 === 'undefined' ? '' : ' ' + item.Surname2)
                            )
                        }
                    }}
                    inputValue={inputValue}
                    getOptionLabel={item =>
                        item.Name +
                        (typeof item.Surname1 === 'undefined' ? '' : ' ' + item.Surname1) +
                        (typeof item.Surname2 === 'undefined' ? '' : ' ' + item.Surname2)
                    }
                    options={options}
                    loading={loading}
                    ListboxProps={{
                        onScroll: handleScroll,
                    }}
                    renderOption={(props, item) => (
                        <li {...props} key={item[Object.keys(item)[0]]}>
                            {' '}
                            {item.Name +
                                (typeof item.Surname1 === 'undefined' ? '' : ' ' + item.Surname1) +
                                (typeof item.Surname2 === 'undefined' ? '' : ' ' + item.Surname2)}
                        </li>
                    )}
                    renderInput={params => (
                        <TextField
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            sx={{ pb: 0 }}
                            {...params}
                            label={t(typeof componentLabel === 'undefined' ? currentPageName : componentLabel)}
                            fullWidth
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CustomSVG strSVG={currentPageIcon} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}

                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
            )}
        />
    ) : (
        <Autocomplete
            freeSolo={true}
            clearIcon={inputValue ? <Clear /> : <Search />}
            open={open}
            value={selectedValue}
            noOptionsText={t('No Values')}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            onInputChange={(event: React.SyntheticEvent, value: string) => {
                if (value === 'undefined') {
                    return
                }
                setInputValue(value)
                setOptions([])
                setPage(1)
                setHasMore(true)
                if (value === '' && handleFilterChange) {
                    handleFilterChange('')
                }
            }}
            onChange={(event: React.SyntheticEvent, item: any | null) => {
                setSelectedValue(item)
                if (item && handleFilterChange) {
                    handleFilterChange(
                        item.Name +
                            (typeof item.Surname1 === 'undefined' ? '' : ' ' + item.Surname1) +
                            (typeof item.Surname2 === 'undefined' ? '' : ' ' + item.Surname2)
                    )
                }
            }}
            inputValue={inputValue}
            getOptionLabel={item =>
                item.Name +
                (typeof item.Surname1 === 'undefined' ? '' : ' ' + item.Surname1) +
                (typeof item.Surname2 === 'undefined' ? '' : ' ' + item.Surname2)
            }
            options={options}
            loading={loading}
            ListboxProps={{
                onScroll: handleScroll,
            }}
            renderOption={(props, item) => (
                <li {...props} key={item[Object.keys(item)[0]]}>
                    {' '}
                    {item.Name +
                        (typeof item.Surname1 === 'undefined' ? '' : ' ' + item.Surname1) +
                        (typeof item.Surname2 === 'undefined' ? '' : ' ' + item.Surname2)}
                </li>
            )}
            renderInput={params => (
                <TextField
                    sx={{ pb: 0 }}
                    {...params}
                    label={t(currentPageName)}
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                <CustomSVG strSVG={currentPageIcon} />
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}

                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}

export default PaginatedAsyncAutocomplete
