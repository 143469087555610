import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
interface ListItemTextAttributesProps {
    item: any
}

const ListItemTextAttributes: React.FC<ListItemTextAttributesProps> = ({ item }) => {
    const { t } = useTranslation()
    return (
        <div>
            <Typography variant="h6" component="span">
                {t(item.Name ? item.Name : '')}
            </Typography>
        </div>
    )
}

export default ListItemTextAttributes
