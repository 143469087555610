import { Typography } from '@mui/material'

import { useTranslation } from 'react-i18next'
interface ListItemTextPrimaryProps {
    text: string
}

const ListItemTextPrimary = (props: ListItemTextPrimaryProps) => {
    const { text } = props
    return (
        <Typography variant="h6" component="h3">
            {text}
        </Typography>
    )
}

export default ListItemTextPrimary
