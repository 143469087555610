import './_SubjectForm.scss'
import { ISubject } from '../ISubject'

import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import { Tab, Container } from '@mui/material/'
import SubjectFormDetails from './Details/SubjectFormDetails'
import { useForm } from 'react-hook-form'
import React from 'react'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import FormLayout from '../../../../../layouts/FormLayout/FormLayout'
import CommonTabHeader from '../../../../Common/CommonTabHeader/CommonTabHeader'
import CommonTabContainer from '../../../../Common/CommonTabContainer/CommonTabContainer'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import CustomSVG from '../../../../Common/CustomSVG/CustomSVG'
import UserForm from '../../../Administration/User/Form/Regular/UserForm'
import UserFormat from '../../../Administration/User/List/ListItemTextAttributes'
import { SUBJECTICON, USERSICON } from '../../../../../styles/_icons'
import CommonTabIcon from '../../../../Common/CommonTabIcon/CommonTabIcon'

const SubjectForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const methods = useForm<ISubject>({ defaultValues: props.item })
    const { handleSubmit, control, setValue } = methods
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [tabValue, setTabValue] = useState('1')
    const [counters, setCounters] = useState<{ [key: string]: string }>({
        users: '0',
    })
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }
    const hanleCountersLoaded = (countersData: { [key: string]: string }) => {
        setCounters(prevCounters => ({
            ...prevCounters,
            ...countersData,
        }))
    }
    const urls = ['subjects/' + item.SubjectID + '/users?count=1']

    return (
        <FormLayout
            countersURLs={urls}
            operation={operation}
            mode={mode}
            item={item}
            onClose={onClose}
            onCloseNoSave={onCloseNoSave}
            onCountersLoaded={hanleCountersLoaded}
            formLabelName="Subject"
            formIDColName="SubjectID"
            formNameColName="Name"
            formAPI="subjects"
            methods={methods}
            tabValue={tabValue}
        >
            <CommonTabHeader handleTabChange={handleTabChange}>
                <Tab label={t('Details')} value="1" icon={<InfoIcon />} />
                {mode === Mode.DeleteEdit && parseInt(counters.users) > 0 && (
                    <Tab
                        label={t('Users')}
                        value="2"
                        icon={<CommonTabIcon strSVG={USERSICON} counter={parseInt(counters.users)} />}
                    />
                )}
            </CommonTabHeader>

            <CommonTabContainer displayTab={'1' === tabValue}>
                <Container>
                    <SubjectFormDetails
                        control={control}
                        setValue={setValue}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                </Container>
            </CommonTabContainer>
            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'2' === tabValue}>
                    <Container>
                        <CommonInfiniteScrollList
                            url={'subjects/' + item.SubjectID + '/users'}
                            isVisibleNow={tabValue === '2'}
                            ListItemTextAttributes={UserFormat}
                            componentParams={{ avatarType: 'images/user' }}
                            CRUDEForm={UserForm}
                            hideAddition={true}
                            CRUDEFormURL="user"
                            hideMultiSelect={true}
                        />
                    </Container>
                </CommonTabContainer>
            )}
        </FormLayout>
    )
}

export default SubjectForm
