import { Suspense } from 'react'
import CommonDialog from '../../CommonDialog/CommonDialog'
import { useTranslation } from 'react-i18next'
import { useCurrentScreen } from '../../../../contexts/CurrentScreenContext'
import { useForm } from 'react-hook-form'
import { useTheme } from '@mui/material'
import { useAuth } from '../../../../contexts/AuthContext'

const FullScreenActionForm: React.FC = () => {
    const { t } = useTranslation()
    const methods = useForm({})
    const { control, setValue, watch } = methods
    const { user } = useAuth()
    const {
        DynamicComponent,
        selectedAction,
        handleProceed,
        openFullActionDialog,
        setOpenFullActionDialog,
        currentScreenItem,
    } = useCurrentScreen()
    const theme = useTheme()
    return (
        <CommonDialog
            open={openFullActionDialog}
            onClose={() => {
                setOpenFullActionDialog(false)
            }}
            title={t(selectedAction?.label + '')}
            customSX={{ zIndex: theme => theme.zIndex.drawer + 1 }}
        >
            <Suspense fallback={<>Loading...</>}>
                {DynamicComponent ? (
                    <DynamicComponent
                        onProceed={(prm: any) =>
                            handleProceed(prm, user!.userName, user!.OpUserID, user!.OrganizationID)
                        }
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        title={selectedAction?.label}
                        dropDownURL={selectedAction?.bulkDropDownURL}
                        dropDownLabel={null}
                        item={currentScreenItem.item}
                    />
                ) : null}
            </Suspense>
        </CommonDialog>
    )
}
export default FullScreenActionForm
