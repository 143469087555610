import { useAuth } from '../../../../../../contexts/AuthContext'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'
import UFTextField from '../../../../../Common/UseForm/UFTextField/UFTextField'
import './_SubjectFormDetails.scss'
import { useTranslation } from 'react-i18next'
//import { useuserContext } from '../../../../../../../contexts/userContext'
interface SubjectFormDetailsProps {
    control: any
    disabled: boolean
    setValue: any
}

const SubjectFormDetails = (props: SubjectFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue } = props

    const { user } = useAuth()

    return (
        <>
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Name"
                label={t('Name')}
                required="Empty field!"
                disabled={disabled}
            />

            <UFSelect
                setValue={setValue}
                name="RateTypeID"
                control={control}
                label={t('Rate Type')}
                idColName="RateTypeID"
                nameColName="Name"
                url={'organizations/' + user!.OrganizationID.toString() + '/ratetypes'}
                disabled={disabled}
                required={t('Required value')}
            />

            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Color"
                label={t('Color')}
                disabled={disabled}
            />

            <UFSelect
                setValue={setValue}
                name="OrganizationID"
                control={control}
                label={t('Organization')}
                idColName="OrganizationID"
                nameColName="Name"
                url={user!.isSuperUser ? 'organizations' : 'organization/' + user!.OrganizationID.toString()}
                disabled={user!.isSuperUser ? disabled : true}
                required={t('Required value')}
                value={user!.OrganizationID.toString()}
            />
        </>
    )
}

export default SubjectFormDetails
