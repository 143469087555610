import { Typography } from '@mui/material'
import { EMAILICON, PHONEICON, ROLESICON, STUDENTSICON, SUBJECTICON } from '../../../../../styles/_icons'
import CustomSVG from '../../../../Common/CustomSVG/CustomSVG'

import { useTranslation } from 'react-i18next'
import ListItemTextPrimary from '../../../../../layouts/List/ListItemTextPrimary'

interface ListItemTextAttributesProps {
    item: any
}

const ListItemTextAttributes: React.FC<ListItemTextAttributesProps> = ({ item }) => {
    const { t } = useTranslation()
    return (
        <div>
            <ListItemTextPrimary
                text={
                    (item.Name ? item.Name : '') +
                    ' ' +
                    (item.Surname1 ? item.Surname1 : '') +
                    ' ' +
                    (item.Surname2 ? item.Surname2 : '')
                }
            />
            <Typography component="span" variant="body2">
                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={PHONEICON} />
                    </div>
                    {item.ContactNumber}
                </div>

                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={EMAILICON} />
                    </div>
                    {item.ContactEmail}
                </div>

                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={ROLESICON} />
                    </div>
                    {item.RoleDSCR + ''}
                </div>

                {item.StudentsDSCR !== '' && (
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={STUDENTSICON} />
                        </div>
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {item.StudentsDSCR === null || typeof item.StudentsDSCR === 'undefined'
                                ? ''
                                : item.StudentsDSCR.replace(/,/g, '\n')}
                        </div>
                    </div>
                )}
                {item.SubjectsDSCR !== '' && item.SubjectsDSCR !== null && typeof item.SubjectsDSCR !== 'undefined' && (
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={SUBJECTICON} />
                        </div>
                        <div style={{ whiteSpace: 'pre-line' }}>
                            {item.SubjectsDSCR === null || typeof item.SubjectsDSCR === 'undefined'
                                ? ''
                                : item.SubjectsDSCR}
                        </div>
                    </div>
                )}
            </Typography>
        </div>
    )
}

export default ListItemTextAttributes
