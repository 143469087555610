import React, { useReducer, useState, useEffect } from 'react'
import {
    List,
    ListItem,
    ListItemText,
    Checkbox,
    Button,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    CircularProgress,
    ListItemIcon,
    Typography,
    Box,
    Chip,
    ListItemSecondaryAction,
    Container,
    Tooltip,
} from '@mui/material'
import { ColorLens, ArrowUpward, ArrowDownward } from '@mui/icons-material'

import CloseIcon from '@mui/icons-material/Close'
import { Methods } from '../../../../Utils/Utils'
import { ALLSELECTEDICON, NONESELECTEDICON } from '../../../../styles/_icons'
import CustomSVG from '../../CustomSVG/CustomSVG'

import api from '../../../../Utils/APIService'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../i18n'

interface ItemType {
    ItemID: string
    Name: string
    selected: boolean
    Color: string
    SortIndex: number
}

type State = ItemType[]
type Action =
    | { type: 'SET_DATA'; payload: ItemType[] }
    | { type: 'TOGGLE_SELECT'; payload: { id: string } }
    | { type: 'SELECT_ALL'; payload: boolean }
    | { type: 'SET_COLOR'; payload: { id: string; Color: string } }

const reorderItems = (items: ItemType[]) => {
    const selectedItems = items
        .filter(item => item.selected)
        .sort((a, b) => Number(a.SortIndex!) - Number(b.SortIndex!))
        .map((item, index) => ({ ...item, SortIndex: index + 1 }))
    const unselectedItems = items.filter(item => !item.selected)
    return [...selectedItems, ...unselectedItems]
}
const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_DATA':
            return action.payload
        case 'TOGGLE_SELECT':
            return state.map(item =>
                item.ItemID === action.payload.id
                    ? { ...item, selected: !item.selected, Color: !item.selected ? '' : item.Color }
                    : item
            )
        case 'SELECT_ALL': {
            const tmpState = state.map(item => ({
                ...item,
                selected: action.payload,
                Color: !item.selected ? '' : item.Color,
            }))
            return reorderItems(tmpState)
        }

        case 'SET_COLOR':
            return state.map(item =>
                item.ItemID === action.payload.id ? { ...item, Color: action.payload.Color } : item
            )
        default:
            return state
    }
}

interface UFOrderListSelectProps {
    name: string
    setValue: any
    url: string
}

const UFOrderListSelect: React.FC<UFOrderListSelectProps> = ({ name, setValue, url }) => {
    const [state, dispatch] = useReducer(reducer, [])
    const [loading, setLoading] = useState(true)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [currentItem, setCurrentItem] = useState<ItemType | null>(null)
    const [selectedColor, setSelectedColor] = useState('')
    const { t } = useTranslation()
    const [selectAll, setSelectAll] = useState(false)
    // Fetch data from the API when the component mounts
    useEffect(() => {
        const fetchData = async () => {
            const response = await api.get(url)

            if (!response.data) {
                const error = (response.data && response.data.message) || response.status
                return Promise.reject(error)
            } else {
                response.data = Methods.JSONUnminify(response.data)
                const fetchedData = response.data.map((item: any) => ({
                    ItemID: item[Object.keys(item)[0]],
                    Name: item.Name,
                    selected: item.selected === 1,
                    Color: item.Color || '',
                    SortIndex: item.SortIndex,
                }))
                dispatch({ type: 'SET_DATA', payload: fetchedData })
            }
            setLoading(false)
        }

        fetchData()
    }, [i18n.language])

    // Open the dialog to select color
    const handleOpenDialog = (item: ItemType) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setCurrentItem(item)
        setSelectedColor(item.Color)
        setDialogOpen(true)
    }

    // Handle dialog color selection
    const handleColorSelect = () => {
        if (currentItem) {
            dispatch({
                type: 'SET_COLOR',
                payload: { id: currentItem.ItemID, Color: selectedColor },
            })
        }
        setDialogOpen(false)
    }

    const toggleSelection = (id: number) => {
        const toggleData = () => {
            const updatedItems = state.map(item =>
                item.ItemID.toString() === id.toString()
                    ? {
                          ...item,
                          selected: !item.selected,
                          SortIndex: !item.selected ? getNextOrderIndex(state) : -1,
                      }
                    : item
            )
            return reorderItems(updatedItems)
        }

        const tmpArra = toggleData()
        dispatch({ type: 'SET_DATA', payload: tmpArra })
    }

    const moveUp = (id: number) => {
        const moveUpData = () => {
            const selectedItems = state.filter(item => item.selected)
            const index = selectedItems.findIndex(item => item.ItemID.toString() === id.toString())
            if (index > 0) {
                ;[selectedItems[index - 1].SortIndex, selectedItems[index].SortIndex] = [
                    Number(selectedItems[index].SortIndex!),
                    Number(selectedItems[index - 1].SortIndex!),
                ]
                return reorderItems([...state])
            }
            return state
        }

        const tmpArra = moveUpData()

        dispatch({ type: 'SET_DATA', payload: tmpArra })
    }

    // Function to move item down
    const moveDown = (id: number) => {
        const moveDownData = () => {
            const selectedItems = state.filter(item => item.selected)
            const index = selectedItems.findIndex(item => item.ItemID.toString() === id.toString())
            if (index < selectedItems.length - 1) {
                ;[selectedItems[index + 1].SortIndex, selectedItems[index].SortIndex] = [
                    Number(selectedItems[index].SortIndex!),
                    Number(selectedItems[index + 1].SortIndex!),
                ]
                return reorderItems([...state])
            }
            return state
        }
        const tmpArra = moveDownData()

        dispatch({ type: 'SET_DATA', payload: tmpArra })
    }

    /*const reorderItems = (items: ItemType[]) => {
        const selectedItems = items
            .filter(item => item.selected)
            .sort((a, b) => Number(a.SortIndex!) - Number(b.SortIndex!))
            .map((item, index) => ({ ...item, SortIndex: index + 1 }))
        const unselectedItems = items.filter(item => !item.selected)
        return [...selectedItems, ...unselectedItems]
    }*/

    // Helper function to get the next order index
    const getNextOrderIndex = (items: ItemType[]) => {
        const selectedItems = items.filter(item => item.selected)
        return selectedItems.length > 0 ? Math.max(...selectedItems.map(item => Number(item.SortIndex!))) + 1 : 1
    }

    useEffect(() => {
        //we take the selected items and set them to the form
        const selectedItems = state.filter(item => item.selected)

        setValue(
            name,
            selectedItems.map(item => ({
                ItemID: item.ItemID,
                Color: item.Color,
                SortIndex: item.SortIndex,
            }))
        )
    }, [state, name, setValue])

    if (loading) {
        return <CircularProgress />
    }
    const handleSelectAllItems = () => {
        setSelectAll(!selectAll)

        const allSelected = state.every(item => item.selected)
        dispatch({ type: 'SELECT_ALL', payload: !allSelected })
    }

    return (
        <>
            {/* <Container sx={{ marginTop: '50px' }}> */}
            <Container sx={{ mt: '0px', pt: '0px', pl: 0, pr: 0 }}>
                <Typography variant="h6" component="h3" onClick={handleSelectAllItems} sx={{ mb: '1rem', mt: '2rem' }}>
                    {t(name)}
                </Typography>
                <Box>
                    {state
                        .filter(item => item.selected)
                        .map(item => (
                            <Chip
                                // @ts-ignore
                                key={item.ItemID}
                                // @ts-ignore
                                label={t(item.Name)}
                                onDelete={() => {
                                    toggleSelection(Number(item.ItemID))
                                }}
                                // deleteIcon={<CloseIcon />}
                                color="primary"
                                sx={{ margin: '0.25rem' }}
                            />
                        ))}
                </Box>
                <Box display="flex" justifyContent="left" alignItems="center" sx={{ mt: '1rem' }}>
                    <Tooltip title="Select all">
                        <IconButton onClick={handleSelectAllItems} sx={{ ml: '1rem' }}>
                            <CustomSVG strSVG={selectAll ? ALLSELECTEDICON : NONESELECTEDICON} customSX={{ mr: 1 }} />
                        </IconButton>
                    </Tooltip>
                    <Typography variant="body2" component="span" onClick={handleSelectAllItems}>
                        {selectAll ? t('Deselect all') : t('Select all')}
                    </Typography>
                </Box>
                <List
                    sx={{
                        position: 'relative',
                        backgroundColor: 'Transparent',
                        // padding: '20px',
                        width: '100%',
                        top: '0px',
                        overflow: 'auto',
                        mb: '2rem',
                    }}
                >
                    {state.map(item => (
                        <ListItem
                            key={item.ItemID}
                            divider
                            className="listItemAvatar"
                            sx={{
                                borderColor: item.selected ? 'secondary.light' : 'white',
                                boxShadow: 'none',
                            }}
                        >
                            {Number(item.SortIndex) !== -1 ? item.SortIndex : ''}
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={item.selected}
                                    tabIndex={-1}
                                    disableRipple
                                    onClick={event => {
                                        event.stopPropagation()
                                        toggleSelection(Number(item.ItemID))
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <div>
                                        <Typography variant="inherit" component="span">
                                            {item.Name}
                                        </Typography>
                                    </div>
                                }
                                sx={{
                                    wordBreak: 'break-all',
                                    whiteSpace: 'normal',
                                    overflowWrap: 'break-word',
                                    width: '100%',
                                    padding: '0.5rem 0',
                                }}
                            />

                            {item.selected && (
                                <Box display="flex" justifyContent="flex-end" alignItems="center" width="100%">
                                    {/* <Box display="flex" justifyContent="center" alignItems="center"></Box> */}
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={handleOpenDialog(item)} sx={{ ml: '1rem' }}>
                                            <ColorLens />
                                        </IconButton>
                                        {/* {item.SortIndex} */}
                                        <IconButton
                                            edge="end"
                                            onClick={() => moveUp(Number(item.ItemID))}
                                            disabled={Number(item.SortIndex) === 1}
                                        >
                                            <ArrowUpward />
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            onClick={() => moveDown(Number(item.ItemID))}
                                            disabled={Number(item.SortIndex) === getNextOrderIndex(state) - 1}
                                        >
                                            <ArrowDownward />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </Box>
                            )}
                        </ListItem>
                    ))}
                </List>
            </Container>
            {/* Color Selection Dialog */}
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Select Color</DialogTitle>
                <DialogContent>
                    <Select value={selectedColor} onChange={e => setSelectedColor(e.target.value)} fullWidth>
                        <MenuItem value="red">Red</MenuItem>
                        <MenuItem value="green">Green</MenuItem>
                        <MenuItem value="blue">Blue</MenuItem>
                        <MenuItem value="yellow">Yellow</MenuItem>
                        <MenuItem value="orange">Orange</MenuItem>
                    </Select>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        variant="contained"
                        fullWidth
                        style={{
                            marginTop: '1rem',
                            marginBottom: '0rem',
                            alignSelf: 'center',
                            backgroundColor: 'var(--secondary-main)',
                            maxWidth: '350px',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleColorSelect}
                        variant="contained"
                        fullWidth
                        style={{
                            marginTop: '1rem',
                            marginBottom: '0rem',
                            alignSelf: 'center',
                            backgroundColor: 'var(--secondary-main)',
                            maxWidth: '350px',
                        }}
                    >
                        Select
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default UFOrderListSelect
