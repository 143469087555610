import './_StudentForm.scss'
import { IStudent } from '../../IStudent'
import { useState } from 'react'
import { Mode, Operation } from '../../../../../Common/Types/CommonType'
import StudentFormEditor from './Editor/StudentFormEditor'
import StudentFormAdd from './Add/StudentFormAdd'
import { useAlert } from '../../../../../../contexts/AlertContext'

interface StudentFormProps {
    item: IStudent
    mode: Mode
    operation: Operation
    onClose: <T>(item: T, afterOp: Operation, error?: string) => void
    onCloseNoSave: () => void
    onImageUpdated: (filename: string) => void
}

const StudentForm = (props: StudentFormProps) => {
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [editMode, setEditMode] = useState<string>('')
    const [apiValue, setApiValue] = useState<string>('')
    const { setAlert } = useAlert()
    const [counters, setCounters] = useState<{ [key: string]: string }>({ students: '0' })

    const handlImageUpdated = (filename: string) => {
        props.onImageUpdated(filename)
    }
    const handleEditCard = (prmType: string) => {
        switch (prmType) {
            case 'namedetails':
                setApiValue('students/namedetails')
                break
            case 'contactdetails':
                setApiValue('students/contactdetails')
                break
            case 'schooldetails':
                setApiValue('students/schooldetails')
                break
            case 'extradetails':
                setApiValue('students/extradetails')
                break
            default:
                break
        }

        setEditMode(prmType)
    }

    const handleOnCloseNoSave = () => {
        if (editMode !== '') {
            setEditMode('')
        } else {
            onCloseNoSave
        }
    }

    const handleOnCloseSave = (prmData: any, prmMethod: Operation, prmError?: string) => {
        if (editMode !== '') {
            onClose(prmData, prmMethod, prmError)
            setEditMode('')
        } else {
            onClose
        }
    }
    return (
        <>
            <StudentFormEditor
                item={props.item}
                mode={mode}
                operation={operation}
                handleOnCloseNoSave={handleOnCloseNoSave}
                handleOnCloseSave={handleOnCloseSave}
                editMode={editMode}
                apiValue={apiValue}
                onImageUpdated={handlImageUpdated}
                handleEditCard={handleEditCard}
            />

            <StudentFormAdd
                editMode={editMode}
                item={props.item}
                mode={mode}
                operation={operation}
                onClose={onClose}
                handleOnCloseNoSave={onCloseNoSave}
                onImageUpdated={handlImageUpdated}
                handleEditCard={handleEditCard}
            />
        </>
    )
}

export default StudentForm
