import './_MealGroupForm.scss'
import { IMealGroup } from '../IMealGroup'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import InfoIcon from '@mui/icons-material/Info'
import Tab from '@mui/material/Tab'
import { Mode, Operation } from '../../../../Common/Types/CommonType'
import MealGroupFormDetails from './Details/MealGroupFormDetails'
import { useForm } from 'react-hook-form'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import React from 'react'
import CommonInfiniteScrollList from '../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import StudentFormat from '../../../Students/Student/List/ListItemTextAttributes'
import MealFormat from '../../Meal/List/ListItemTextAttributes'
import { CommonFormProps } from '../../../../Common/CommonForm/ICommonForm'
import StudentForm from '../../../Students/Student/Form/Regular/StudentForm'
import MealForm from '../../Meal/Form/MealForm'
import FormLayout from '../../../../../layouts/FormLayout/FormLayout'
import CommonTabContainer from '../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../Common/CommonTabHeader/CommonTabHeader'
import { Container } from '@mui/material'
import CommonTabIcon from '../../../../Common/CommonTabIcon/CommonTabIcon'

const MealGroupForm = (props: CommonFormProps) => {
    const { t } = useTranslation()
    const methods = useForm<IMealGroup>({ defaultValues: props.item })
    const { control, setValue } = methods
    const { item, operation, mode, onClose, onCloseNoSave } = props
    const [tabValue, setTabValue] = useState('1')
    const [counters, setCounters] = useState<{ [key: string]: string }>({ students: '0', meals: '0' })
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }

    const hanleCountersLoaded = (countersData: { [key: string]: string }) => {
        setCounters(prevCounters => ({
            ...prevCounters,
            ...countersData,
        }))
    }

    const urls = [
        'mealgroups/' + item.MealGroupID + '/students?count=1',
        'mealgroups/' + item.MealGroupID + '/meals?count=1',
    ]

    return (
        <FormLayout
            countersURLs={urls}
            operation={operation}
            mode={mode}
            item={item}
            onClose={onClose}
            onCloseNoSave={onCloseNoSave}
            onCountersLoaded={hanleCountersLoaded}
            formLabelName="MealGroup"
            formIDColName="MealGroupID"
            formNameColName="Name"
            formAPI="mealgroups"
            methods={methods}
            tabValue={tabValue}
        >
            <CommonTabHeader handleTabChange={handleTabChange}>
                <Tab label={t('Details')} value="1" icon={<InfoIcon />} />
                {mode === Mode.DeleteEdit && parseInt(counters.students) > 0 && (
                    <Tab
                        label={t('Students')}
                        value="2"
                        icon={<CommonTabIcon icon={<AccountBoxIcon />} counter={parseInt(counters.students)} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                    <Tab
                        label={t('Meals')}
                        value="3"
                        icon={<CommonTabIcon icon={<AccountBoxIcon />} counter={parseInt(counters.meals)} />}
                    />
                )}
            </CommonTabHeader>

            <CommonTabContainer displayTab={'1' === tabValue}>
                <Container>
                    <MealGroupFormDetails
                        item={item}
                        control={control}
                        setValue={setValue}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                </Container>
            </CommonTabContainer>
            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'2' === tabValue}>
                    {/*<StudentList
                                handleListLoaded={handleListLoaded}
                                isVisibleNow={tabValue === '2'}
                                url={'mealgroups/' + item.MealGroupID + '/students'}
                    />*/}

                    <CommonInfiniteScrollList
                        url={'mealgroups/' + item.MealGroupID + '/students'}
                        isVisibleNow={tabValue === '2'}
                        ListItemTextAttributes={StudentFormat}
                        CRUDEForm={StudentForm}
                        hideAddition={true}
                        componentParams={{ avatarType: 'images/student' }}
                        CRUDEFormURL="student"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'3' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'mealgroups/' + item.MealGroupID + '/meals'}
                        isVisibleNow={tabValue === '3'}
                        ListItemTextAttributes={MealFormat}
                        CRUDEForm={MealForm}
                        hideAddition={true}
                        CRUDEFormURL="meal"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
        </FormLayout>
    )
}

export default MealGroupForm
