import './_StudentFormSchoolDetails.scss'
import { IStudent } from '../../../IStudent'
import UFSelect from '../../../../../../Common/UseForm/UFSelect/UFSelect'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'

interface StudentFormSchoolDetailsProps {
    item: IStudent
    control: any
    setValue: any
    disabled: boolean
}

const StudentFormSchoolDetails = (props: StudentFormSchoolDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    return (
        <Container>
            <UFSelect
                setValue={setValue}
                name="OrganizationID"
                control={control}
                label={t('Organization')}
                idColName="OrganizationID"
                nameColName="Name"
                url="organizations"
                disabled={disabled}
                required={t('Required value')}
            />
            <UFSelect
                setValue={setValue}
                name="LevelID"
                control={control}
                label={t('Level')}
                idColName="LevelID"
                nameColName="Name"
                url="levels"
                disabled={disabled}
                required={t('Required value')}
            />
        </Container>
    )
}

export default StudentFormSchoolDetails
