import { Fab, Tooltip } from '@mui/material'
import { t } from 'i18next'
import { Add } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

interface ListAdditionButtonProps {
    handleOpenAdd: (event: any) => void
}

const ListAdditionButton: React.FC<ListAdditionButtonProps> = (props: ListAdditionButtonProps) => {
    const { handleOpenAdd } = props
    const { t } = useTranslation()
    return (
        <Fab
            sx={{
                position: 'fixed',
                bottom: 16,
                right: 16,
            }}
            variant="extended"
            color="primary"
            onClick={event => handleOpenAdd(event)}
            aria-label={t('Add') + ' ' + 'Ctrl/CMD + A'}
        >
            <Tooltip title={t('Add') + ' ' + 'Ctrl/CMD + A'}>
                <Add
                    sx={{
                        position: 'relative',
                        mr: 1,
                    }}
                />
            </Tooltip>
            {t('Add')}
        </Fab>
    )
}
export default ListAdditionButton
