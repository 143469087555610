import './_SignupConfirm.scss'
import React, { useState } from 'react'
import { Button, Container, Grid, Typography, Link } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { createTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { ISignupConfirm } from './ISignupConfirm'
import { useForm } from 'react-hook-form'
import Copyright from '../Copyright/Copyright'
import { useAuth } from '../../../../contexts/AuthContext'
import api from '../../../../Utils/APIService'
import { useAlert } from '../../../../contexts/AlertContext'

interface SignupConfirmProps {
    token?: string
}

const SignupConfirm: React.FC<SignupConfirmProps> = () => {
    const { signupConfirm } = useAuth()
    const [loading, setLoading] = useState(false)

    const params = useParams()
    const token = params.token
    const theme = createTheme()
    const { t } = useTranslation()
    const { setAlert } = useAlert()
    const methods = useForm<ISignupConfirm>({ defaultValues: { Name: '', ContactNumber: '' } })
    const { handleSubmit, control, setValue } = methods

    const handleSignupConfirm = async (prm: ISignupConfirm) => {
        setLoading(true)
        signupConfirm(token!, prm.Name, prm.ContactNumber)
        setLoading(false)
    }

    const handleError = (errors: any) => {
        setAlert({
            message: t('Error'),
            severity: 'error',
            datetime: Date.now().toString(),
        })
    }
    return (
        <>
            <Container
                maxWidth="sm"
                sx={{
                    marginTop: 8,
                }}
            >
                <form onSubmit={handleSubmit(handleSignupConfirm, handleError)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h5" align="center">
                                {t('Confirm Onboarding')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <UFTextField
                                setValue={setValue}
                                control={control}
                                fullWidth={true}
                                name="Name"
                                label={t('Name')}
                                required="Empty field!"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <UFTextField
                                setValue={setValue}
                                control={control}
                                fullWidth={true}
                                name="ContactNumber"
                                label={t('Contact Number')}
                                required="Empty field!"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                                {loading ? 'Loading...' : 'Confirm Onboarding'}
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2" color="textPrimary" align="left">
                                <Link underline="hover" href="/login">
                                    {t('Home')}
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Container>
            <Copyright />
        </>
    )
}

export default SignupConfirm
