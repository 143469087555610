import React, { createContext, useContext, useEffect, useState } from 'react'
import { Session, initialSession } from '../models/session'
import { useNavigate } from 'react-router-dom'
import { authService } from '../Utils/AuthService'
import { useSocket } from './SocketContext'
import api, { refreshToken } from '../Utils/APIService'
import { Methods } from '../Utils/Utils'
import { useTranslation } from 'react-i18next'
import { useAlert } from './AlertContext'
import i18n from '../i18n'

// Define types for user and context

type AuthContextType = {
    user: Session | null
    isAuthenticated: boolean
    isKeepMeLoggedIn: boolean
    setUserDetails: (details: Session | null) => void
    //getUserDetails: () => Session;
    login: (
        username: string,
        password: string,
        twofactorcode: string,
        googleAuthCode: string,
        keepLoggedIn: boolean
    ) => Promise<void>
    loginGoogle: (
        username: string,
        password: string,
        twofactorcode: string,
        googleAuthCode: string,
        keepLoggedIn: boolean
    ) => Promise<void>
    logout: () => void
    signupConfirm: (token: string, name: string, contactnumber: string) => void
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

// Define a helper hook to use the AuthContext
export const useAuth = () => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}

interface AuthContextProviderProps {
    children?: React.ReactNode
}

// Define the AuthProvider component

export const AuthProvider: React.FC<AuthContextProviderProps> = ({ children }) => {
    const [user, setUserDetails] = useState<Session | null>(initialSession)
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(authService.isAuthenticated())
    const [isKeepMeLoggedIn, setIsKeepMeLoggedIn] = useState<boolean>(authService.isKeepMeLoggedIn())
    const [notificationReceived, setNotificationReceived] = useState<boolean>(false)
    const { socket, isSocketReady, handleNotifications } = useSocket()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { setAlert } = useAlert()
    //const { setCurrentPageName } = useCurrentScreen()
    useEffect(() => {
        const initializeAuth = async () => {
            // Check if user has tokens in storage (isAuthenticated checks token validity)
            if (authService.isKeepMeLoggedIn()) {
                setIsKeepMeLoggedIn(true)
                if (authService.isTokenExpired()) {
                    const isRefreshed = await refreshToken()

                    if (isRefreshed) {
                        // Token refreshed successfully, fetch user details
                        setIsAuthenticated(true)
                        await getUserDetailsFromServer()
                    } else {
                        // Refresh failed, log out and redirect to login
                        setIsAuthenticated(false)
                    }
                } else {
                    // Token is valid, fetch user details
                    setIsAuthenticated(true)
                    await getUserDetailsFromServer()
                }
            } else {
                setIsKeepMeLoggedIn(false)
                // No valid token, log out
                setIsAuthenticated(false)
            }
        }

        if (isSocketReady) {
            initializeAuth() // Run the initialization check when the app loads
        }
    }, [isSocketReady, i18n.language])

    const getUserDetailsFromServer = async () => {
        try {
            const response = await api.get('users/getuserdetailsforalreadylogged') // GET user details

            if (typeof response.data.menusKeys !== 'undefined') {
                const keyArray = response.data.menusKeys
                const dataArray = response.data.menus
                const tmpMenusAndKeys = { keyArray, dataArray }
                response.data.menus = Methods.JSONUnminify(tmpMenusAndKeys)
                setUserDetails({
                    ...user,
                    userName: response.data.Name,
                    menus: response.data.menus,
                    OpUserID: response.data.UserID,
                    OrganizationID: response.data.OrganizationID,
                    OrganizationDSCR: response.data.OrganizationDSCR,
                    redirectPath: response.data.defaultDashboard,
                    isSuperUser: response.data.isSuperUser === 1 ? true : false,
                    ProfileImg: response.data.ProfileImg,
                })

                //setCurrentPageName(response.data.OrganizationDSCR)
                if (socket) {
                    handleNotifications(response.data.UserID)
                    const socketData = {
                        UserID: response.data.UserID,
                        Name: response.data.Name,
                    }
                    socket.emit('register_user', socketData, handleCallBack)
                } else {
                    console.log('Socket not available')
                }

                //navigate(response.data.defaultDashboard); // Redirect after login
            } else {
                setAlert({
                    message: 'Error getting the details from server, language is: ' + i18n.language,
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
                logout() // In case of failure, log out the user
            }
        } catch (error) {
            setAlert({
                message: 'Error getting the details from server',
                severity: 'error',
                datetime: Date.now().toString(),
            })
            logout() // In case of failure, log out the user
        }
    }

    const login = async (
        username: string,
        password: string,
        twofactorcode: string,
        googleAuthCode: string,
        keepLoggedIn: boolean
    ) => {
        try {
            const prmItem = {
                Email: username,
                Password: password,
                TwoFactorCode: twofactorcode,
                googleAuthCode: googleAuthCode,
            }

            //const response = await api.post('users/login', prmItem);
            //We have to do it with axios so we dont do the interceptor
            const response = await api.post(process.env.REACT_APP_METHODS_API + 'users/login', prmItem)

            if (typeof response.data.menusKeys !== 'undefined') {
                const keyArray = response.data.menusKeys
                const dataArray = response.data.menus
                const tmpMenusAndKeys = { keyArray, dataArray }
                response.data.menus = Methods.JSONUnminify(tmpMenusAndKeys)
            }

            setUserDetails({
                ...user,
                userName: response.data.Name,
                menus: response.data.menus,
                OpUserID: response.data.UserID,
                OrganizationID: response.data.OrganizationID,
                OrganizationDSCR: response.data.OrganizationDSCR,
                redirectPath: response.data.defaultDashboard,
                isSuperUser: response.data.isSuperUser === 1 ? true : false,
                ProfileImg: response.data.ProfileImg,
                //ProfileImg: (prmType === 'googleLogin' ? prmLogin.googlePicture : response.data.ProfileImg),
            })
            //setCurrentPageName(response.data.OrganizationDSCR)
            authService.saveToken(
                response.data.accessToken,
                response.data.refreshToken,
                response.data.expiresIn,
                keepLoggedIn
            )
            setIsAuthenticated(true)
            if (socket) {
                const socketData = {
                    UserID: response.data.UserID,
                    Name: response.data.Name,
                }
                socket.emit('register_user', socketData, handleCallBack)
            }

            navigate(response.data.defaultDashboard) // Redirect after login
        } catch (error) {
            console.error('Login error:', error)
            setAlert({
                message: 'Error logging in',
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }

    const loginGoogle = async (
        username: string,
        password: string,
        twofactorcode: string,
        googleAuthCode: string,
        keepLoggedIn: boolean
    ) => {
        try {
            const prmItem = {
                Email: username,
                Password: password,
                TwoFactorCode: twofactorcode,
                googleAuthCode: googleAuthCode,
            }

            const response = await api.post('users/logingoogle', prmItem)

            if (typeof response.data.menusKeys !== 'undefined') {
                const keyArray = response.data.menusKeys
                const dataArray = response.data.menus
                const tmpMenusAndKeys = { keyArray, dataArray }
                response.data.menus = Methods.JSONUnminify(tmpMenusAndKeys)
            }

            setUserDetails({
                ...user,
                userName: response.data.Name,
                menus: response.data.menus,
                OpUserID: response.data.UserID,
                OrganizationID: response.data.OrganizationID,
                OrganizationDSCR: response.data.OrganizationDSCR,
                redirectPath: response.data.defaultDashboard,
                isSuperUser: response.data.isSuperUser === 1 ? true : false,
                ProfileImg: response.data.ProfileImg,
                //ProfileImg: (prmType === 'googleLogin' ? prmLogin.googlePicture : response.data.ProfileImg),
            })
            //setCurrentPageName(response.data.OrganizationDSCR)
            authService.saveToken(
                response.data.accessToken,
                response.data.refreshToken,
                response.data.expiresIn,
                keepLoggedIn
            )
            setIsAuthenticated(true)
            if (socket) {
                const socketData = {
                    UserID: response.data.UserID,
                    Name: response.data.Name,
                }
                socket.emit('register_user', socketData, handleCallBack)
            }

            navigate(response.data.defaultDashboard) // Redirect after login
        } catch (error) {
            console.error('Login error:', error)
            setAlert({
                message: 'Error logging in with google credentials',
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }

    const logout = () => {
        authService.removeToken() // Remove tokens from storage
        setIsAuthenticated(false)
        setIsKeepMeLoggedIn(false)
        if (socket) {
            console.log('Removing user from socket')
            socket.emit('disconnect_user', user?.OpUserID, handleCallBack)
        }
        setUserDetails({
            ...user,
            userName: '',
            menus: [],
            OpUserID: -1,
            OrganizationID: -1,
            OrganizationDSCR: '',
            redirectPath: '',
            isSuperUser: false,
            ProfileImg: '',
        })
        navigate('/')
    }

    const handleCallBack = (item: any) => {
        console.log('Callback from socket register_user and connected users are:', item.connectedUsersNames)
    }

    const signupConfirm = async (token: string, name: string, contactnumber: string) => {
        try {
            const prmItem = {
                Token: token,
                Name: name,
                ContactNumber: contactnumber,
            }

            const response = await api.post('users/signupconfirm', JSON.stringify(prmItem))

            if (!response.data) {
                const errorData = response.data

                setAlert({
                    message: t(errorData.msg),
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            } else {
                const data = response.data
                if (typeof data.menusKeys !== 'undefined') {
                    const keyArray = data.menusKeys
                    const dataArray = data.menus
                    const tmpMenusAndKeys = { keyArray, dataArray }
                    data.menus = Methods.JSONUnminify(tmpMenusAndKeys)
                }

                setUserDetails({
                    ...user,
                    userName: data.Name,
                    menus: data.menus,
                    OpUserID: data.UserID,
                    OrganizationID: data.OrganizationID,
                    OrganizationDSCR: data.OrganizationDSCR,
                    redirectPath: data.defaultDashboard,
                    isSuperUser: data.isSuperUser === 1 ? true : false,
                    ProfileImg: data.ProfileImg,
                })

                authService.saveToken(
                    response.data.accessToken,
                    response.data.refreshToken,
                    response.data.expiresIn,
                    false
                )

                if (socket) {
                    const socketData = {
                        UserID: data.UserID,
                        Name: data.Name,
                    }
                    socket.emit('register_user', socketData, handleCallBack)
                }

                navigate(data.defaultDashboard)
            }
        } catch (error) {
            console.error('Signup confirmation error:', error)
            setAlert({
                message: 'Error signing up',
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }

    /*if (!isSocketReady) {
        // Optionally render a loading indicator until the socket is ready
        return <div>{t('Waiting for socket connection...')}</div>
    }*/

    return (
        <AuthContext.Provider
            value={{
                user,
                setUserDetails,
                isAuthenticated,
                isKeepMeLoggedIn,
                logout,
                login,
                loginGoogle,
                signupConfirm,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
