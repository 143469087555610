import { Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { forwardRef } from 'react'

const CommonTransition = forwardRef(function Transition(
    {
        direction = 'left', // Default direction if undefined
        children,
        ...restProps
    }: TransitionProps & {
        direction?: 'left' | 'right' | 'up' | 'down'
        children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>
) {
    return (
        <Slide direction={direction} ref={ref} {...restProps}>
            {children}
        </Slide>
    )
})

export default CommonTransition
