import './_UserFormAdd.scss'
import { IUser } from '../../../IUser'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import Tab from '@mui/material/Tab'
import { Mode, Operation } from '../../../../../../Common/Types/CommonType'
import UserFormDetails from '../Details/UserFormDetails'
import { useForm } from 'react-hook-form'
import React from 'react'
import CustomSVG from '../../../../../../Common/CustomSVG/CustomSVG'
import {
    CARESICON,
    MEALSICON,
    STUDENTSICON,
    INFOICON,
    USERGROUPSICON,
    SUBJECTICON,
} from '../../../../../../../styles/_icons'
import UserCard from '../../../Card/UserCard'
import CareFormat from '../../../../../Cares/Care/List/ListItemTextAttributes'
import MealFormat from '../../../../../Meals/Meal/List/ListItemTextAttributes'
import StudentFormat from '../../../../../Students/Student/List/ListItemTextAttributes'
import CommonInfiniteScrollList from '../../../../../../Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import UserGroupFormat from '../../../../UserGroup/List/ListItemTextAttributes'
import CareForm from '../../../../../Cares/Care/Form/CareForm'
import MealForm from '../../../../../Meals/Meal/Form/MealForm'
import StudentForm from '../../../../../Students/Student/Form/Regular/StudentForm'
import UserGroupForm from '../../../../UserGroup/Form/UserGroupForm'
import CommonTabContainer from '../../../../../../Common/CommonTabContainer/CommonTabContainer'
import CommonTabHeader from '../../../../../../Common/CommonTabHeader/CommonTabHeader'
import { Container, useTheme } from '@mui/material'
import Profile from '../../../../../../../layouts/Profile/Profile'
import SubjectForm from '../../../../../Students/Subject/Form/SubjectForm'
import CommonTabIcon from '../../../../../../Common/CommonTabIcon/CommonTabIcon'

interface UserFormAddProps {
    item: IUser
    mode: Mode
    operation: Operation
    onClose: <T>(item: T, afterOp: Operation, error?: string) => void
    onImageUpdated: (filename: string) => void
    counters: { [key: string]: string }
    handleOnCloseNoSave: () => void
    editMode: string
    handleEditCard: (prmType: string) => void
}

const UserFormAdd = (props: UserFormAddProps) => {
    const { t } = useTranslation()
    const methods = useForm<IUser>({ defaultValues: props.item })
    const { control, setValue } = methods
    const { item, mode, operation, handleOnCloseNoSave, editMode, onImageUpdated, onClose, handleEditCard } = props
    const [tabValue, setTabValue] = useState('1')
    const [counters, setCounters] = useState<{ [key: string]: string }>({
        cares: '0',
        meals: '0',
        students: '0',
        usergroups: '0',
        subjects: '0',
    })
    const theme = useTheme()
    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
    }
    const handleListLoaded = (name: string, count: number) => {
        console.log('handleListLoaded', name, count)
    }
    const handlImageUpdated = (filename: string) => {
        props.onImageUpdated(filename)
    }

    let urls: string[] = []
    if (typeof item.UserID != 'undefined') {
        urls = [
            'users/' + item.UserID + '/meals?count=1',
            'users/' + item.UserID + '/cares?count=1',
            'users/' + item.UserID + '/students?count=1',
            'users/' + item.UserID + '/usergroups?count=1',
            'users/' + item.UserID + '/subjects?count=1',
        ]
    }

    const handleCountersLoaded = (countersData: { [key: string]: string }) => {
        setCounters(prevCounters => ({
            ...prevCounters,
            ...countersData,
        }))
    }
    return (
        <Profile
            item={item}
            onCloseNoSave={handleOnCloseNoSave}
            tabValue={tabValue}
            methods={methods}
            onClickEdit={handleEditCard}
            nameLabelValue={item.Name + ' ' + item.Surname1 + ' ' + item.Surname2}
            subNameLabelValue={typeof item.RoleDSCR !== 'undefined' ? item.RoleDSCR : ''}
            IDColName="UserID"
            avatarType="images/user"
            onCountersLoaded={handleCountersLoaded}
            countersURLs={urls}
        >
            <CommonTabHeader handleTabChange={handleTabChange} customSX={theme.customSx.globalFormTabListForProfile}>
                <Tab label={t('Details')} value="1" icon={<CustomSVG strSVG={INFOICON} />} />
                {mode === Mode.DeleteEdit && parseInt(counters.students) > 0 && (
                    <Tab
                        label={t('My Students')}
                        value="2"
                        icon={<CommonTabIcon strSVG={STUDENTSICON} counter={parseInt(counters.students)} />}
                    />
                )}

                {mode === Mode.DeleteEdit && parseInt(counters.cares) > 0 && (
                    <Tab
                        label={t('Cares')}
                        value="4"
                        icon={<CommonTabIcon strSVG={CARESICON} counter={parseInt(counters.cares)} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                    <Tab
                        label={t('Meals')}
                        value="5"
                        icon={<CommonTabIcon strSVG={MEALSICON} counter={parseInt(counters.meals)} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                    <Tab
                        label={t('User Groups')}
                        value="6"
                        icon={<CommonTabIcon strSVG={USERGROUPSICON} counter={parseInt(counters.usergroups)} />}
                    />
                )}
                {mode === Mode.DeleteEdit && parseInt(counters.meals) > 0 && (
                    <Tab
                        label={t('Subjects')}
                        value="7"
                        icon={<CommonTabIcon strSVG={SUBJECTICON} counter={parseInt(counters.subjects)} />}
                    />
                )}
            </CommonTabHeader>

            {mode === Mode.Add && (
                <CommonTabContainer displayTab={'1' === tabValue}>
                    <Container>
                        <UserFormDetails
                            control={control}
                            setValue={setValue}
                            item={item}
                            disabled={operation === Operation.Delete ? true : false}
                            onImageUpdated={handlImageUpdated}
                        />
                    </Container>
                </CommonTabContainer>
            )}
            {editMode === '' && mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'1' === tabValue}>
                    <UserCard item={item} handleEditCard={handleEditCard} />
                </CommonTabContainer>
            )}
            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'2' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/students'}
                        isVisibleNow={tabValue === '2'}
                        ListItemTextAttributes={StudentFormat}
                        CRUDEForm={StudentForm}
                        hideAddition={true}
                        componentParams={{ avatarType: 'images/student' }}
                        CRUDEFormURL="student"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}

            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'4' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/cares'}
                        isVisibleNow={tabValue === '4'}
                        ListItemTextAttributes={CareFormat}
                        CRUDEForm={CareForm}
                        hideAddition={true}
                        CRUDEFormURL="care"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}

            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'5' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/meals'}
                        isVisibleNow={tabValue === '5'}
                        ListItemTextAttributes={MealFormat}
                        CRUDEForm={MealForm}
                        hideAddition={true}
                        CRUDEFormURL="meal"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'6' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/usergroups'}
                        isVisibleNow={tabValue === '6'}
                        ListItemTextAttributes={UserGroupFormat}
                        CRUDEForm={UserGroupForm}
                        hideAddition={true}
                        CRUDEFormURL="usergroup"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
            {mode === Mode.DeleteEdit && (
                <CommonTabContainer displayTab={'7' === tabValue}>
                    <CommonInfiniteScrollList
                        url={'users/' + item.UserID + '/subjects'}
                        isVisibleNow={tabValue === '7'}
                        ListItemTextAttributes={UserGroupFormat}
                        CRUDEForm={SubjectForm}
                        hideAddition={true}
                        CRUDEFormURL="subject"
                        hideMultiSelect={true}
                    />
                </CommonTabContainer>
            )}
        </Profile>
    )
}

export default UserFormAdd
