import { Methods } from '../../../../../../Utils/Utils'
import { CONTACTDETAILSICON, EMAILICON, PHONEICON } from '../../../../../../styles/_icons'
import CustomSVG from '../../../../../Common/CustomSVG/CustomSVG'
import { IStudent } from '../../IStudent'
import './_ContactDetails.scss'

interface IContactDetailsProps {
    item: IStudent
}

const ContactDetails = (props: IContactDetailsProps) => {
    const { item } = props
    return (
        <>
            {(Methods.isPopulated(item.ContactName1) ||
                Methods.isPopulated(item.ContactNumber1) ||
                Methods.isPopulated(item.ContactEmail1)) && (
                <div className="group-container">
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={CONTACTDETAILSICON} />
                        </div>
                        <div>{Methods.formatText(item.ContactName1, 'Contact Name 1')}</div>
                    </div>
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={PHONEICON} />
                        </div>
                        <div>{Methods.formatText(item.ContactNumber1, 'Contact Number')}</div>
                    </div>
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={EMAILICON} />
                        </div>
                        {Methods.formatText(item.ContactEmail1, 'Contact Email')}
                    </div>
                </div>
            )}
            {(Methods.isPopulated(item.ContactName2) ||
                Methods.isPopulated(item.ContactNumber2) ||
                Methods.isPopulated(item.ContactEmail2)) && (
                <div className="group-container">
                    <br />
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={CONTACTDETAILSICON} />
                        </div>
                        <div>{Methods.formatText(item.ContactName2, 'Contact Name 2')}</div>
                    </div>
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={PHONEICON} />
                        </div>
                        <div>{Methods.formatText(item.ContactNumber2, 'Contact Number')}</div>
                    </div>
                    <div className="icon-container">
                        <div className="icon">
                            <CustomSVG strSVG={EMAILICON} />
                        </div>
                        {Methods.formatText(item.ContactEmail2, 'Contact Email')}
                    </div>
                </div>
            )}
        </>
    )
}
export default ContactDetails
