import './_UFDatePicker.scss'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller } from 'react-hook-form'
import { IInputProps } from '../../Interfaces/IInputProps'
import { TextField } from '@mui/material'
import { useState } from 'react'

const DATE_FORMAT = 'YYYY/MM/DD'

export const UFFormatDate = (dateValue: any) => {
    if (dateValue !== undefined && dateValue !== '') {
        if (typeof dateValue === 'object') {
            if (typeof dateValue.format === 'function') {
                dateValue = dateValue.format('YYYY/MM/DD')
            } else {
                const year = dateValue.toLocaleString('default', { year: 'numeric' })
                const month = dateValue.toLocaleString('default', { month: '2-digit' })
                const day = dateValue.toLocaleString('default', { day: '2-digit' })
                dateValue = year + '/' + month + '/' + day
            }
        } else {
            let tmpDate = ''
            if (typeof dateValue !== 'undefined') {
                tmpDate = dateValue.split('T')[0]
                if (typeof dateValue.split('T')[1] != 'undefined') {
                    tmpDate = tmpDate + ' ' + dateValue.split('T')[1].split('.')[0]
                }
            }
            dateValue = tmpDate
        }
    } else {
        if (dateValue === '') {
            dateValue = new Date()
            const year = dateValue.toLocaleString('default', { year: 'numeric' })
            const month = dateValue.toLocaleString('default', { month: '2-digit' })
            const day = dateValue.toLocaleString('default', { day: '2-digit' })
            dateValue = year + '/' + month + '/' + day
        } else {
            dateValue = ''
        }
    }
    return dateValue
}

const UFDatePicker = (props: IInputProps) => {
    const { name, control, label } = props

    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    return (
        <Controller
            name={name}
            control={control}
            //defaultValue={value === undefined || value === '' ? new Date() : value}
            defaultValue={new Date()}
            render={({ field }) => (
                <div onClick={handleOpen} style={{ width: '100%', marginTop: '1rem', cursor: 'pointer' }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DesktopDatePicker
                            data-test={name}
                            label={label}
                            inputFormat={DATE_FORMAT}
                            renderInput={params => <TextField {...params} fullWidth={true} onClick={handleOpen} />}
                            onChange={newValue => {
                                field.onChange(newValue)
                                setOpen(false)
                            }}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            open={open}
                            value={typeof field.value === 'undefined' ? new Date() : field.value}
                        />
                    </LocalizationProvider>
                </div>
            )}
        />
    )
}

export default UFDatePicker
