import { Box, Badge } from '@mui/material'
import CustomSVG from '../CustomSVG/CustomSVG'

interface CommonTabIconProps {
    strSVG?: string
    counter: number
    icon?: React.ReactNode
}

const CommonTabIcon = (props: CommonTabIconProps) => {
    const { strSVG, counter, icon } = props

    return (
        <Box display="flex" alignItems="center">
            {strSVG && <CustomSVG strSVG={strSVG} />}
            {!strSVG && icon}
            <Badge badgeContent={counter} overlap="circular" sx={{ ml: '0.75rem' }} />
        </Box>
    )
}

export default CommonTabIcon
