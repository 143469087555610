import './_SelectorAutocomplete.scss'
import { useCallback, useEffect, useReducer } from 'react'
import { ICommon } from '../Types/ICommon'

import { AlertColor } from '@mui/material/Alert'
import { Autocomplete, Chip, TextField } from '@mui/material'
import { Methods } from '../../../Utils/Utils'
import api from '../../../Utils/APIService'
interface itemListState {
    items: ICommon[]
    itemsLoading: boolean
    itemsLoaded: boolean
    parentItems: number[]
    parentItemsLoading: boolean
    parentItemsLoaded: boolean
    error: boolean
    alertOpened: boolean
    alertSeverity: AlertColor
    alertMessage: string
}
const initialItemState: itemListState = {
    items: [],
    itemsLoaded: false,
    itemsLoading: true,
    parentItems: [],
    parentItemsLoaded: false,
    parentItemsLoading: true,
    error: false,
    alertOpened: false,
    alertSeverity: 'success',
    alertMessage: '',
}

type itemListActions =
    | { type: 'LoadItems' }
    | { type: 'ItemsLoaded'; payload: ICommon[] }
    | { type: 'LoadParentItems' }
    | { type: 'ParentItemsLoaded'; payload: number[] }
    | { type: 'CloseAlert' }
    | { type: 'Error'; payload: string }

const ItemReducer = (state: itemListState, action: itemListActions): itemListState => {
    switch (action.type) {
        case 'LoadItems':
            return { ...state, itemsLoading: true, itemsLoaded: false }
        case 'LoadParentItems':
            return { ...state, parentItemsLoading: true, parentItemsLoaded: false }
        case 'ItemsLoaded':
            return {
                ...state,
                items: action.payload,
                itemsLoading: false,
                itemsLoaded: true,
                error: false,
            }
        case 'ParentItemsLoaded':
            return {
                ...state,
                parentItems: action.payload,
                parentItemsLoading: false,
                parentItemsLoaded: true,
                error: false,
            }
        case 'Error':
            return {
                ...state,
                error: true,
                itemsLoading: false,
                itemsLoaded: false,
                parentItemsLoading: false,
                parentItemsLoaded: false,
            }
        case 'CloseAlert':
            return { ...state, alertOpened: false }
        default:
            return state
    }
}

export interface ISelectorAutocompleteProps {
    name: string
    label: string
    url?: string
    idColName?: string
    nameColName?: string
    alreadyExistingItems: ICommon[]
    handleMoreItems: (par: ICommon) => void | null
}

const SelectorAutocomplete = (props: ISelectorAutocompleteProps) => {
    const { name, url, idColName, nameColName, label, handleMoreItems, alreadyExistingItems } = props

    const [state, dispatch] = useReducer(ItemReducer, initialItemState)
    //const [selectedItems, setSelectedItems] = useState<ICommon[]>([]);

    const loadItems = useCallback(async () => {
        let tmpURL = process.env.REACT_APP_METHODS_API + ''
        //tmpURL = tmpURL + (typeof api === 'undefined' ? '' : api) + '/1'
        tmpURL = tmpURL + url

        const response = await api.get(tmpURL)

        if (!response.data) {
            // get error message from body or default to response status
            const error = (response.data && response.data.message) || response.status
            dispatch({ type: 'Error', payload: error })
            return Promise.reject(error)
        } else {
            response.data = Methods.JSONUnminify(response.data)

            // @ts-ignore
            dispatch({
                type: 'ItemsLoaded',
                payload: response.data.filter(
                    // @ts-ignore
                    a => !alreadyExistingItems.map(b => b[idColName]).includes(a[idColName])
                ),
            })
        }
    }, [url, alreadyExistingItems, idColName])

    useEffect(() => {
        loadItems()
    }, [loadItems])

    /*useEffect(() => {
        setValue(name, selectedItems);
    }, [selectedItems]);*/

    const handleOnChange = (event: object, value: any) => {
        // @ts-ignore
        //setSelectedItems(value)

        handleMoreItems(value)
    }

    return (
        <div style={{ width: '100%', marginTop: '1rem' }}>
            {state.itemsLoaded && (
                <Autocomplete
                    multiple
                    id={name}
                    onChange={handleOnChange}
                    // @ts-ignore
                    options={state.items}
                    // @ts-ignore
                    getOptionLabel={option => option[nameColName]}
                    // @ts-ignore
                    defaultValue={state.items.filter(x => state.parentItems.includes(x[idColName]))}
                    freeSolo
                    // @ts-ignore
                    renderTags={(value: readonly ICommon[], getTagProps) =>
                        //value.map((option: string, index: number) => (
                        value.map((option: ICommon, index: number) =>
                            //<Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            // @ts-ignore
                            ({
                                /*<Chip
                                key={index}
                                variant="outlined"
                                label={option[nameColName]}
                                {...getTagProps({ index })}
                        />*/
                            })
                        )
                    }
                    renderInput={params => <TextField {...params} variant="filled" label={label} placeholder={label} />}
                />
            )}
        </div>
    )
}

export default SelectorAutocomplete
