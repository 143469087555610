import './_StudentFormNameDetails.scss'
import { IStudent } from '../../../IStudent'
import UFTextField from '../../../../../../Common/UseForm/UFTextField/UFTextField'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'

interface StudentFormNameDetailsProps {
    item: IStudent
    control: any
    setValue: any
    disabled: boolean
}

const StudentFormNameDetails = (props: StudentFormNameDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    return (
        <Container>
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Name"
                label={t('Name')}
                required={t('Empty field!')}
                disabled={disabled}
            />
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Surname1"
                label={t('Surname1')}
                required={t('Empty field!')}
                disabled={disabled}
            />
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Surname2"
                label={t('Surname2')}
                required={t('Empty field!')}
                disabled={disabled}
            />
        </Container>
    )
}

export default StudentFormNameDetails
