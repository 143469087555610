import { ThemeContext } from '@emotion/react'
import { createTheme, styled, useThemeProps } from '@mui/material/'
// import { blue } from '@mui/material/colors'
import type {} from '@mui/x-data-grid/themeAugmentation' //for the datagrid

/*declare module '@mui/material/styles' {
    interface div {
        variants?: any
    }
}*/

declare module '@mui/material/styles' {
    interface Theme {
        customSx: {
            [key: string]: any
            globalFormTab: (isVisible: boolean) => any
        }
    }

    interface ThemeOptions {
        customSx?: {
            [key: string]: any
            globalFormTab: (isVisible: boolean) => any
        }
    }
}

const eskolaTheme = createTheme({
    // Customize the components
    zIndex: {
        drawer: 1400,
        modal: 1300,
    },
    // Breakpoints reference as a guide
    // xs, extra-small: 0px
    // sm, small: 600px
    // md, medium: 900px
    // lg, large: 1200px
    // xl, extra-large: 1536px

    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        //So we display over the Dialog too
        /*MuiDrawer: {
            styleOverrides: {
                root: {
                    zIndex: 1400,
                },
            },
        },*/
        // Generic CSS tokens
        MuiCssBaseline: {
            styleOverrides: {
                ':root': {
                    '--black': '#000',
                    '--white': '#fff',
                    '--primary-main': '#1D535C',
                    '--primary-light': '#4A757D',
                    '--primary-dark': '#17424A',
                    '--primary-contrast-text': '#fff',
                    '--secondary-main': '#4ECDC4',
                    '--secondary-light': '#71d7d0',
                    '--secondary-dark': '#32b1a8',
                    '--secondary-contrast-text': '#fff',
                    '--error-main': '#d32f2f',
                    '--error-light': '#ef5350',
                    '--error-dark': '#c62828',
                    '--error-contrast-text': '#fff',
                    '--warning-main': '#ed6c02',
                    '--warning-light': '#ff9800',
                    '--warning-dark': '#e65100',
                    '--warning-contrast-text': '#fff',
                    '--info-main': '#0288d1',
                    '--info-light': '#03a9f4',
                    '--info-dark': '#01579b',
                    '--info-contrast-text': '#fff',
                    '--success-main': '#2e7d32',
                    '--success-light': '#4caf50',
                    '--success-dark': '#1b5e20',
                    '--success-contrast-text': '#fff',
                    '--grey-50': '#fafafa',
                    '--grey-100': '#f5f5f5',
                    '--grey-200': '#eeeeee',
                    '--grey-300': '#e0e0e0',
                    '--grey-400': '#bdbdbd',
                    '--grey-500': '#9e9e9e',
                    '--grey-600': '#757575',
                    '--grey-700': '#616161',
                    '--grey-800': '#424242',
                    '--grey-900': '#212121',
                    '--grey-A100': '#f5f5f5',
                    '--grey-A200': '#eeeeee',
                    '--grey-A400': '#bdbdbd',
                    '--grey-A700': '#616161',
                    '--link': 'var(--secondary-dark)',
                },
                '&.filterFixedBar': {
                    position: 'fixed',
                    padding: '0',
                    width: '100%',
                    margin: 'auto',
                    backgroundColor: 'var(--white)',
                    zIndex: '2',
                    gap: '0.25rem',
                },
                '&.icon-container': {
                    color: 'var(--grey-500)',
                },
                '&.listItemAvatar': {
                    display: 'flex',
                    gap: '0.5rem',
                    flexDirection: 'row',
                    cursor: 'pointer',
                    userSelect: 'none',
                    marginBottom: '1rem',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    boxShadow: '0 1px 2px rgba(0,0,0,0.25)',
                },
                '&.listItemText': {
                    wordBreak: 'break-all',
                    whiteSpace: 'normal',
                    overflowWrap: 'break-word',
                },
                '&.swipeBar': {
                    width: '30px',
                    height: '6px',
                    backgroundColor: 'rgb(224, 224, 224)',
                    borderRadius: '3px',
                    position: 'absolute',
                    top: '8px',
                    left: 'calc(50% - 15px)',
                    marginBottom: '1.5rem',
                },
                '&.listToolBar': {
                    margin: '0 1rem',
                },
                '&.toolbar': {
                    width: 'auto',
                    paddingLeft: 0,
                    paddingRight: 0,
                },
                // '&.bulkActionsContainer': {
                //     display: 'flex',
                //     flexDirection: 'row',
                //     justifyContent: 'space-between',
                //     alignItems: 'center',
                // },

                '&.actionContainer': {
                    width: '100%',
                    maxWidth: '580px',
                },
                '&.actionTitle': {
                    display: 'flex',
                    width: '100%',
                    maxWidth: '580px',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                },
                '&.actionForm': {
                    width: '100%',
                    maxWidth: '90%',
                    '@media (min-width:900px)': {
                        // sm breakpoint
                        maxWidth: '580px',
                    },
                    m: 'auto',
                    textAlign: 'left',
                    p: '1rem',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    '&.swipeUp': {
                        height: 'auto',
                        // maxHeight: '92vh',
                        overflowY: 'auto',
                        //m: 0,
                        margin: '0 auto',
                        left: 0,
                        right: 0,
                        bottom: 0,
                        width: '100%',
                        borderTopLeftRadius: '8',
                        borderTopRightRadius: '8',
                        padding: 0,
                    },
                },
            },
        },
        // Custom the main container
        MuiContainer: {
            styleOverrides: {
                root: {
                    // border: '1px solid var(--secondary-main)',
                    marginTop: '1rem',
                    padding: '1rem',
                    '&.containerTool': {
                        paddingLeft: '0rem',
                        paddingRight: '0rem',
                        '@media (min-width:600px)': {
                            // sm breakpoint
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem',
                        },
                    },
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    boxShadow: 'none', // Remove shadow globally for all AppBars
                },
            },
        },
        // Data grid
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    //backgroundColor: 'red',
                    '&:hover, &.Mui-hovered': {
                        cursor: 'pointer',
                    },
                    // Reset on touch devices, it doesn't add specificity
                    '@media (hover: none)': {
                        backgroundColor: 'var(--primary-main)',
                        cursor: 'pointer',
                    },
                },
            },
        },
        // Dialog
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--white)',
                    color: 'var(--white)',
                    userSelect: 'none',
                },
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--grey-100)',
                    padding: 0,
                },
            },
        },
        // Link
        MuiLink: {
            styleOverrides: {
                root: {
                    color: 'var(--link)',
                    '&:hover': {
                        color: 'var(--primary-dark)',
                    },
                    '&:active': {
                        color: 'var(--primary-main)',
                    },
                    '&:focus': {
                        color: 'var(--primary-dark)',
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    '& .MuiBadge-badge': {
                        border: `1px solid var(--white)`,
                        padding: '0 4px',
                        backgroundColor: 'var(--grey-100)',
                    },
                },
            },
        },
        // Button styles
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    background: 'var(--primary-main)',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                    },
                    '&:focus': {
                        boxShadow: '0 0 0 0.2rem var(--secondary-light)',
                    },
                },
            },
        },
        // Fab button
        MuiFab: {
            styleOverrides: {
                root: {
                    background: 'var(--primary-main)',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: 'var(--secondary-main)',
                        borderColor: 'var(--secondary-main)',
                        boxShadow: 'none',
                    },
                    '&:active': {
                        boxShadow: 'none',
                        backgroundColor: 'var(--primary-dark)',
                        borderColor: 'var(--primary-dark)',
                    },
                    '&:focus': {
                        boxShadow: '0 0 0 0.2rem var(--primary-main)',
                    },
                },
            },
        },
        // List
        MuiListItem: {
            styleOverrides: {
                root: {
                    backgroundColor: 'var(--white)',
                    paddingBottom: 0,
                    paddingTop: 0,
                    borderBottom: '2px solid Transparent',
                },
            },
        },
        MuiListItemAvatar: {
            styleOverrides: {
                root: {
                    marginTop: '0.75rem',
                    alignSelf: 'flex-start',
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'auto',
                },
            },
        },
        // Form
        MuiFormControl: {
            styleOverrides: {
                root: {
                    paddingBottom: '1rem',
                    '&:focus, &.Mui-focused': {},
                },
            },
        },
        // Focus state
        MuiFormLabel: {
            styleOverrides: {
                root: {},
            },
        },
        // Focus state
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    '&.MuiInputLabel-root.MuiInputLabel-shrink': {
                        lineHeight: 1.475,
                    },
                    '&:focus, &.Mui-focused': {
                        color: 'var(--secondary-main)',
                    },
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    backgroundColor: 'var(--white)',
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: 'var(--secondary-main)', // Change the global focus border color
                    },
                },
                input: {
                    height: '1rem',
                    '&:focus, &.Mui-focused': {},
                    '&::placeholder': {
                        color: '#757575', // Placeholder text color
                        opacity: 1, // Ensure the placeholder is fully visible
                        fontSize: '0.875rem', // Placeholder font size
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    backgroundColor: 'var(--white)',
                },
                input: {
                    padding: 0,
                    '&:-webkit-autofill': {
                        boxShadow: '0 0 0 100px white inset', // Override background color (remove yellow autofill)
                        WebkitBoxShadow: '0 0 0 100px white inset', // For older WebKit versions
                        WebkitTextFillColor: 'black', // Override autofill text color
                        transition: 'background-color 5000s ease-in-out 0s', // Prevent flashing on focus
                    },
                },
            },
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    padding: 0,
                    backgroundColor: 'var(--white)',
                    '&:focus, &.Mui-focused': {},
                },
            },
        },
        // Alert
        MuiAlert: {
            styleOverrides: {
                root: {
                    zIndex: 1500, // Set z-index to be above everything else
                    position: 'fixed',
                    top: '25%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center horizontally and vertically
                    minWidth: '300px',
                    mb: 2,
                    boxShadow: '0px 0px 8px rgba(0,0,0,0.25)',
                    borderRadius: 0,
                },
            },
        },
        // Autocomplete
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    margin: '0 auto',
                    padding: '1rem 0',
                    width: 'calc(100vw - 2rem )',
                    '@media (min-width:900px)': {
                        maxWidth: '350px',
                    },
                    '&.withoutMaxWidth': {
                        maxWidth: '700px',
                    },
                    '&.MuiAutocomplete-hasClearIcon': {
                        // paddingRight: '0.5rem', // Remove padding-right for clear icon
                    },
                    '&.MuiAutocomplete-inputRoot': {
                        '&.MuiInputBase-input': {
                            height: '100%', // Ensure the input field covers the entire height
                            padding: '7.5px 4px 7.5px 6px',
                        },
                    },
                    '& .MuiAutocomplete-clearIndicator': {
                        transform: 'none', // Prevent rotation on hover
                        visibility: 'visible', // Ensure the icon is always visible
                        cursor: 'default', // Optionally set to default cursor
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '.MuiTabs-flexContainer': {
                        '@media (min-width:600px)': {
                            // sm breakpoint
                            justifyContent: 'center',
                        },
                    },
                },
            },
        },
        // Toolbar
        MuiToolbar: {
            styleOverrides: {
                root: {
                    '&.MuiToolbar-dense': {
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingTop: 0,
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    padding: '7.5px 4px 7.5px 16px',
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '0 1rem',
                    '&.selectAll': {
                        padding: '1rem',
                    },
                },
            },
        },
        MuiTypography: {
            // defaultProps: {
            //     variantMapping: {
            //         h1: 'h2',
            //         h2: 'h2',
            //         h3: 'h2',
            //         h4: 'h2',
            //         h5: 'h2',
            //         h6: 'h2',
            //         subtitle1: 'h2',
            //         subtitle2: 'h2',
            //         body1: 'span',
            //         body2: 'span',
            //     },
            // },
            styleOverrides: {
                root: {
                    '&.pageTitle': {
                        fontSize: '1rem',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: {
                            xs: '200px',
                            md: '100%',
                            lg: '100%',
                            xl: '100%',
                        },
                    },
                    '&.dialogTitle': {
                        fontSize: '1rem',
                        fontWeight: 400,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: {
                            xs: '200px',
                            md: '100%',
                            lg: '100%',
                            xl: '100%',
                        },
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '.MuiChip-deleteIcon': {
                        color: 'var(--secondary-main)',
                        opacity: 0.25,
                        '&:hover': {
                            color: 'var(--secondary-main)',
                            opacity: 1,
                        },
                    },
                },
            },
        },
        // Data grid
        /*MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: 'primary.light',
                    '&.Mui-checked': {
                        color: 'white',
                    },
                },
            },
        },*/
    },

    // Typography
    typography: {
        htmlFontSize: 16,
        fontFamily: ['Roboto', 'Helvetica Neue', 'Arial', 'sans-serif'].join(','),
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
    },

    palette: {
        primary: {
            light: '#4A757D',
            main: '#1D535C',
            dark: '#17424A',
            contrastText: '#fff',
        },
        secondary: {
            light: '#71d7d0',
            main: '#4ECDC4',
            dark: '#32b1a8',
            contrastText: '#000',
        },
        common: {
            white: '#ffffff',
            black: '#000000',
        },
    },

    customSx: {
        // TabList styles
        globalFormTabList: {
            backgroundColor: 'var(--white)',
            zIndex: (theme: any) => theme.zIndex.modal + 1,
            position: 'fixed',
            width: '100%',
            top: '56px',
            button: {
                fontSize: '12px',
            },
        },
        globalFormTabListForProfile: {
            backgroundColor: 'var(--white)',
            zIndex: (theme: any) => theme.zIndex.modal + 1,
            position: 'fixed',
            width: '100%',
            top: '204px',
            button: {
                fontSize: '12px',
            },
        },
        globalFormTab: (isVisible: boolean) => ({
            display: isVisible ? 'block' : 'none',
            position: 'relative',
            padding: 0,
            top: '72px',
        }),
    },
})

export interface DivFlexProps {
    variant?: '' | 'desktop'
}

export const DivFlex = styled('div', {
    // Configure which props should be forwarded on DOM
    shouldForwardProp: prop => prop !== 'variant',
    /*name: 'DivFlex',
    slot: 'Root',*/
    // We are specifying here how the styleOverrides are being applied based on props
    /*overridesResolver: (props, styles) => [
        styles.root
    ],*/
    name: 'MuiDivFlex',
    slot: 'Root',
    skipVariantsResolver: true, // disables theme.components[name].variants
    //skipSx: true,
})<DivFlexProps>(({ theme, variant }) => ({
    display: 'flex',
    alignItems: 'center',
    alignContent: 'space-between',
    flexDirection: variant === '' ? 'column' : 'row',
    padding: '1rem',
    marginTop: '1rem',
    //bgcolor: theme.palette.primary,
    //backgroundColor: 'LIGHTblue',
    borderRadius: 1,
}))

export default eskolaTheme
