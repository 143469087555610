import './_CareGroupFormDetails.scss'
import UFTextField from '../../../../../Common/UseForm/UFTextField/UFTextField'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'
import UFAutocomplete from '../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useTranslation } from 'react-i18next'
import { ICareGroup } from '../../ICareGroup'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import { useAuth } from '../../../../../../contexts/AuthContext'
import PaginatedAsyncAutocomplete from '../../../../../Common/PaginatedAsyncAutocomplete/PaginatedAsyncAutocomplete'
import { Box, Stack, Divider, Chip, Avatar, ListItem } from '@mui/material'
interface CareGroupFormDetailsProps {
    item: ICareGroup
    control: any
    disabled: boolean
    setValue: any
}

const CareGroupFormDetails = (props: CareGroupFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, item, setValue } = props

    const { user } = useAuth()
    return (
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="center"
            alignItems="flexStart"
            // spacing={{ xs: 1, sm: 2, md: 4, lg: 4, xl: 4 }}
            divider={<Divider orientation="vertical" flexItem />}
        >
            <Box sx={{ margin: { xs: '0rem 0rem 0 0rem', md: '2rem 1.5rem 0 1.5rem' } }}>
                <UFTextField
                    setValue={setValue}
                    control={control}
                    fullWidth={true}
                    name="Name"
                    label={t('Care group name')}
                    required="Empty field!"
                    disabled={disabled}
                />
                <UFSelect
                    name="CareUserID"
                    setValue={setValue}
                    control={control}
                    label={t('CareUserName')}
                    idColName="UserID"
                    nameColName="Name"
                    url={'organizations/' + user!.OrganizationID + '/users'}
                    disabled={disabled}
                    required={t('Required value')}
                />
                <PaginatedAsyncAutocomplete
                    name="OrganizationID"
                    componentLabel={t('Organization')}
                    control={control}
                    setValue={setValue}
                    url={user!.isSuperUser ? 'organizations' : 'organization/' + user!.OrganizationID.toString()}
                    defaultValue={{
                        id: user!.OrganizationID,
                        label: user!.OrganizationDSCR.toString(),
                    }}
                    disabled={user!.isSuperUser ? disabled : true}
                    required="Empty field!"
                />
            </Box>
            <Box sx={{ flexGrow: 2 }}>
                <UFAutocomplete
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    url={'organizations/' + user!.OrganizationID + '/students'}
                    parentApi={'caregroups/' + item.CareGroupID + '/students'}
                    disabled={disabled}
                    startSelectedItems={[]}
                />
            </Box>
            {/*<UFMultiSelect
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    api={'organizations/' + session.OrganizationID + '/students'}
                    parentApi={'caregroups/' + item.CareGroupID + '/students'}
                    disabled={disabled}
                    startSelectedItems={[]}
                />*/}
        </Stack>
    )
}

export default CareGroupFormDetails
