import './_StudentFormExtraDetails.scss'
import { IStudent } from '../../../IStudent'
import UFTextField from '../../../../../../Common/UseForm/UFTextField/UFTextField'
import UFMultiSelect from '../../../../../../Common/UseForm/UFMultiSelect/UFMultiSelect'
import UFDatePicker from '../../../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import UFCheckBox from '../../../../../../Common/UseForm/UFCheckBox/UFCheckBox'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { useTranslation } from 'react-i18next'
import { Container } from '@mui/material'

interface StudentFormExtraDetailsProps {
    item: IStudent
    control: any
    setValue: any
    disabled: boolean
}

const StudentFormExtraDetails = (props: StudentFormExtraDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    return (
        <Container>
            <UFDatePicker name="BirthDate" control={control} label={t('BirthDate')} disabled={disabled} />

            <UFCheckBox name="SpecialReqs" control={control} label={t('Special Requirements')} disabled={disabled} />
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Note"
                label={t('Note')}
                disabled={disabled}
            />
            <UFMultiSelect
                name="Allergies"
                control={control}
                setValue={setValue}
                label={t('Allergies')}
                idColName="AllergyID"
                nameColName="Name"
                url="allergies"
                parentApi={'students/' + item.StudentID + '/allergies'}
                disabled={disabled}
                startSelectedItems={[]}
            />
        </Container>
    )
}

export default StudentFormExtraDetails
