import './_FormLayout.scss'

import { useTranslation } from 'react-i18next'
import { ReactNode, useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import React from 'react'
import { Methods } from '../../Utils/Utils'
import { CommonFormProps } from '../../components/Common/CommonForm/ICommonForm'
import CommonTransition from '../../components/Common/CommonTransition/CommonTransition'
import SaveCancelToolBar from '../../components/Common/SaveCancelToolBar/SaveCancelToolBar'
import { Mode, Operation } from '../../components/Common/Types/CommonType'
import { useAlert } from '../../contexts/AlertContext'
import TabContext from '@mui/lab/TabContext'
import { useCurrentScreen } from '../../contexts/CurrentScreenContext'
import ActionForFormMode from '../../components/Common/MenuActions/ActionForFormMode/ActionForFormMode'

interface formLayoutProps extends CommonFormProps {
    children?: ReactNode
    item: any
    operation: Operation
    mode: Mode
    onClose: <T>(item: T, afterOp: Operation, error?: string) => void
    onCloseNoSave: () => void
    onCountersLoaded: (counters: { [key: string]: string }) => void
    countersURLs: string[]
    formLabelName: string
    formIDColName: string
    formNameColName: string
    formAPI: string
    methods: any
    tabValue: string
}

const FormLayout = (props: formLayoutProps) => {
    const { t } = useTranslation()

    const {
        item,
        operation,
        mode,
        onClose,
        onCloseNoSave,
        countersURLs,
        formLabelName,
        formIDColName,
        formNameColName,
        formAPI,
        children,
        onCountersLoaded,
        tabValue,
        methods,
    } = props

    const {
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = methods

    const { setAlert } = useAlert()
    const { popMode, afterActionCallback, setAfterActionCallback } = useCurrentScreen()

    useEffect(() => {
        console.log('FormLayout: useEffect afterActionCallback:', JSON.stringify(afterActionCallback))
        if (afterActionCallback.operation !== Operation.Initial) {
            console.log('FormLayout: useEffect afterActionCallback we go ahead')
            if (afterActionCallback.save === false) {
                onCloseNoSave()
            } else {
                if (afterActionCallback.save === true) {
                    onClose(afterActionCallback.item, afterActionCallback.operation)
                }
            }
            popMode()
            setAfterActionCallback({ operation: Operation.Initial, save: false, item: null, error: '' })
        }
    }, [afterActionCallback])

    useEffect(() => {
        const getCountersData = async () => {
            try {
                const countersData = await Methods.getCountersBulk(countersURLs)

                onCountersLoaded(countersData)
            } catch (error) {
                console.log(error)
                setAlert({
                    message: t('Error'),
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            }
        }

        if (mode === Mode.DeleteEdit) {
            getCountersData()
        }
    }, [])

    const handleOnCloseNoSave = () => {
        popMode()
        onCloseNoSave()
    }
    return (
        <Dialog open={true} onClose={handleOnCloseNoSave} fullScreen={true} TransitionComponent={CommonTransition}>
            <SaveCancelToolBar
                url={formAPI}
                idName={formIDColName}
                title={
                    operation === Operation.Add ? t(formLabelName) : t(item[formNameColName]) + ' ' + t(formLabelName)
                }
                item={item}
                mode={mode}
                operation={operation}
                onSave={onClose}
                onCloseNoSave={handleOnCloseNoSave}
                handleSubmit={handleSubmit}
            />
            <DialogContent sx={{ position: 'relative', width: '100%', height: '100vh' }}>
                <TabContext value={tabValue}>
                    {React.Children.map(children, child => {
                        if (React.isValidElement(child)) {
                            const propsToPass = { control, setValue, errors }
                            return React.cloneElement(child, propsToPass)
                        }
                        return child
                    })}
                </TabContext>
            </DialogContent>
        </Dialog>
    )
}

export default FormLayout
