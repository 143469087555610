import './_UserGroupFormDetails.scss'
import UFTextField from '../../../../../Common/UseForm/UFTextField/UFTextField'
import UFSelect from '../../../../../Common/UseForm/UFSelect/UFSelect'
import UFAutocomplete from '../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { useTranslation } from 'react-i18next'
import { IUserGroup } from '../../IUserGroup'
import { DivFlex } from '../../../../../../theme/eskolaTheme'
import { useAuth } from '../../../../../../contexts/AuthContext'
import PaginatedAsyncAutocomplete from '../../../../../Common/PaginatedAsyncAutocomplete/PaginatedAsyncAutocomplete'
interface UserGroupFormDetailsProps {
    item: IUserGroup
    control: any
    disabled: boolean
    setValue: any
}

const UserGroupFormDetails = (props: UserGroupFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, item, setValue } = props

    const { user } = useAuth()
    return (
        <>
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Name"
                label={t('Name')}
                required="Empty field!"
                disabled={disabled}
            />
            <PaginatedAsyncAutocomplete
                name="OrganizationID"
                componentLabel={t('Organization')}
                control={control}
                setValue={setValue}
                url={user!.isSuperUser ? 'organizations' : 'organization/' + user!.OrganizationID.toString()}
                defaultValue={{
                    id: user!.OrganizationID,
                    label: user!.OrganizationDSCR.toString(),
                }}
                disabled={user!.isSuperUser ? disabled : true}
                required="Empty field!"
            />
            <UFSelect
                name="MainUserID"
                setValue={setValue}
                control={control}
                label={t('MainUserName')}
                idColName="UserID"
                nameColName="Name"
                url={'organizations/' + user!.OrganizationID + '/users?ref=1'}
                disabled={disabled}
                required={t('Required value')}
            />
            <UFAutocomplete
                name="Users"
                control={control}
                setValue={setValue}
                label={t('Users')}
                idColName="UserID"
                nameColName="Name"
                url={'organizations/' + user!.OrganizationID + '/users'}
                parentApi={'usergroups/' + item.UserGroupID + '/users'}
                disabled={disabled}
                startSelectedItems={[]}
            />
        </>
    )
}

export default UserGroupFormDetails
