import './_UserFormDetails.scss'
import UFTextField from '../../../../../../Common/UseForm/UFTextField/UFTextField'
import UFSelect from '../../../../../../Common/UseForm/UFSelect/UFSelect'
import UFCheckBox from '../../../../../../Common/UseForm/UFCheckBox/UFCheckBox'
import { useTranslation } from 'react-i18next'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { DivFlex } from '../../../../../../../theme/eskolaTheme'
import { IUser } from '../../../IUser'
import CustomAvatar from '../../../../../../Common/CustomAvatar/CustomAvatar'
import UFAutocomplete from '../../../../../../Common/UseForm/UFAutocomplete/UFAutocomplete'
import { EInputType } from '../../../../../../Common/Interfaces/IInputProps'
import { useAuth } from '../../../../../../../contexts/AuthContext'
import UFOrderListSelect from '../../../../../../Common/UseForm/UFOrderListSelect/UFOrderListSelect'
const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
    ({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        width: '100%',
        marginTop: '1rem',
    })
)

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

interface UserFormDetailsProps {
    control: any
    disabled: boolean
    setValue: any
    item: IUser
    onImageUpdated: (filename: string) => void
}

const UserFormDetails = (props: UserFormDetailsProps) => {
    const { t } = useTranslation()
    const { control, disabled, setValue, item } = props
    const [expanded, setExpanded] = useState<string | false>('panel0')

    const { user } = useAuth()

    const handleChangeAccordion = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false)
    }

    const handlImageUpdated = (filename: string) => {
        props.onImageUpdated(filename)
    }

    return (
        <>
            <CustomAvatar
                itemType="images/user"
                keyName="UserID"
                item={item}
                onImageUpdated={handlImageUpdated}
                sizeHeight={200}
                sizeWidth={200}
            />

            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Name"
                label={t('Name')}
                required="Empty field!"
                disabled={disabled}
            />
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Surname1"
                label={t('Surname1')}
                required="Empty field!"
                disabled={disabled}
            />
            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Surname2"
                label={t('Surname2')}
                required="Empty field!"
                disabled={disabled}
            />

            <UFCheckBox control={control} name="IsEmployee" label={t('Is Employee')} disabled={disabled} />

            <UFTextField
                setValue={setValue}
                control={control}
                fullWidth={true}
                name="Email"
                label={t('Email')}
                required={t('Empty field!')}
                disabled={disabled}
                inputType={EInputType.Email}
            />

            <Accordion
                expanded={expanded === 'panel0'}
                onChange={handleChangeAccordion('panel0')}
                sx={{ width: '100%', marginTop: '1rem' }}
            >
                <AccordionSummary aria-controls="panel0d-content" id="panel0d-header">
                    {t('Contact Details')}
                </AccordionSummary>
                <AccordionDetails>
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="ContactName"
                        label={t('ContactName')}
                        required={t('Empty field!')}
                        disabled={disabled}
                    />
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="ContactNumber"
                        label={t('ContactNumber')}
                        required={t('Empty field!')}
                        disabled={disabled}
                    />
                    <UFTextField
                        setValue={setValue}
                        control={control}
                        fullWidth={true}
                        name="ContactEmail"
                        label={t('ContactEmail')}
                        required={t('Empty field!')}
                        disabled={disabled}
                        inputType={EInputType.Email}
                    />
                </AccordionDetails>
            </Accordion>
            <UFSelect
                setValue={setValue}
                name="OrganizationID"
                control={control}
                label={t('Organization')}
                idColName="OrganizationID"
                nameColName="Name"
                url={'organizations'}
                disabled={disabled}
                required={t('Required value')}
            />
            <UFSelect
                setValue={setValue}
                name="RoleID"
                control={control}
                label={t('Role')}
                idColName="RoleID"
                nameColName="Name"
                url={'organizations/' + user!.OrganizationID + '/roles'}
                disabled={disabled}
                required={t('Required value')}
            />
            {/*<UFMultiSelect
                    name="Students"
                    control={control}
                    setValue={setValue}
                    label={t('Students')}
                    idColName="StudentID"
                    nameColName="Name"
                    url={"organizations/" + session.OrganizationID + "/students"}
                    parentApi={"users/" + item.UserID + "/students"}
                    disabled={disabled}
                    startSelectedItems={[]}
/>*/}

            <UFAutocomplete
                name="Students"
                control={control}
                setValue={setValue}
                label={t('Students')}
                idColName="StudentID"
                nameColName="Name"
                url={'organizations/' + user!.OrganizationID + '/students'}
                parentApi={'users/' + item.UserID + '/students'}
                disabled={disabled}
                chipImageName="ProfileImg"
                startSelectedItems={[]}
            />

            <UFOrderListSelect
                name="Subjects"
                setValue={setValue}
                url={
                    typeof item.UserID === 'undefined'
                        ? 'organizations/' + user!.OrganizationID + '/subjects'
                        : 'users/' + item.UserID + '/usersubjectsselectedunselected'
                }
            />
        </>
    )
}

export default UserFormDetails
