import { useTranslation } from 'react-i18next'
import ListItemTextPrimary from '../../../../../layouts/List/ListItemTextPrimary'
interface ListItemTextAttributesProps {
    item: any
}

const ListItemTextAttributes: React.FC<ListItemTextAttributesProps> = ({ item }) => {
    return <ListItemTextPrimary text={item.Name} />
}

export default ListItemTextAttributes
