import ListItemTextPrimary from '../../../../../layouts/List/ListItemTextPrimary'
import { UFFormatDate } from '../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import { UFFormatTime } from '../../../../Common/UseForm/UFTimePicker/UFTimePicker'

interface ListItemTextAttributesProps {
    item: any
}

const ListItemTextAttributes: React.FC<ListItemTextAttributesProps> = ({ item }) => {
    return <ListItemTextPrimary text={UFFormatDate(item.Date) + ' ' + UFFormatTime(item.Hour)} />
}

export default ListItemTextAttributes
