import { useTranslation } from 'react-i18next'
import { UFFormatDate } from '../../../../Common/UseForm/UFDatePicker/UFDatePicker'
import { UFFormatTime } from '../../../../Common/UseForm/UFTimePicker/UFTimePicker'
import ListItemTextPrimary from '../../../../../layouts/List/ListItemTextPrimary'

interface ListItemTextAttributesProps {
    item: any
}

const ListItemTextAttributes: React.FC<ListItemTextAttributesProps> = ({ item }) => {
    const { t } = useTranslation()
    return (
        <div>
            <ListItemTextPrimary text={UFFormatDate(item.Date) + ' ' + UFFormatTime(item.Hour)} />
        </div>
    )
}

export default ListItemTextAttributes
