import { useTranslation } from 'react-i18next'
import { Mode, Operation } from '../../../../../../Common/Types/CommonType'
import { IStudent } from '../../../IStudent'
import { Dialog, DialogContent } from '@mui/material'
import SaveCancelToolBar from '../../../../../../Common/SaveCancelToolBar/SaveCancelToolBar'
import StudentCard from '../../../Card/StudentCard'
import { useForm } from 'react-hook-form'
import CommonTransition from '../../../../../../Common/CommonTransition/CommonTransition'
import StudentFormNameDetails from '../../NameDetails/Details/StudentFormNameDetails'
import StudentFormContactDetails from '../../ContactDetails/Details/StudentFormContactDetails'
import StudentFormSchoolDetails from '../../SchoolDetails/Details/StudentFormSchoolDetails'
import StudentFormExtraDetails from '../../ExtraDetails/Details/StudentFormExtraDetails'

interface StudentFormEditorProps {
    item: IStudent
    mode: Mode
    operation: Operation
    handleOnCloseNoSave: () => void
    handleOnCloseSave: <T>(item: T, afterOp: Operation, error?: string) => void
    editMode: string
    apiValue: string
    onImageUpdated: (filename: string) => void
    handleEditCard: (prmType: string) => void
}

const StudentFormEditor = (props: StudentFormEditorProps) => {
    const {
        item,
        mode,
        operation,
        handleOnCloseNoSave,
        handleOnCloseSave,
        editMode,
        apiValue,
        onImageUpdated,
        handleEditCard,
    } = props
    const methods = useForm<IStudent>({ defaultValues: item })
    const { handleSubmit, control, setValue } = methods
    const { t } = useTranslation()
    const handlImageUpdated = (filename: string) => {
        onImageUpdated(filename)
    }
    return (
        <Dialog
            open={editMode !== ''}
            onClose={handleOnCloseNoSave}
            fullScreen={true}
            TransitionComponent={CommonTransition}
        >
            <SaveCancelToolBar
                url={apiValue}
                idName="StudentID"
                title={item.Name + ' ' + item.Surname1 + ' ' + item.Surname2 + ' ' + t('Student')}
                item={props.item}
                mode={mode}
                operation={operation}
                onSave={handleOnCloseSave}
                onCloseNoSave={handleOnCloseNoSave}
                handleSubmit={handleSubmit}
                hideCommonActions={true}
            />

            <DialogContent>
                {editMode === 'namedetails' && (
                    <StudentFormNameDetails
                        control={control}
                        item={item}
                        setValue={setValue}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                )}

                {editMode === 'contactdetails' && (
                    <StudentFormContactDetails
                        control={control}
                        item={item}
                        setValue={setValue}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                )}

                {editMode === 'schooldetails' && (
                    <StudentFormSchoolDetails
                        control={control}
                        item={item}
                        setValue={setValue}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                )}

                {editMode === 'extradetails' && (
                    <StudentFormExtraDetails
                        control={control}
                        item={item}
                        setValue={setValue}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                )}

                {editMode === '' && mode === Mode.DeleteEdit && (
                    <StudentCard item={item} handleEditCard={handleEditCard} />
                )}
            </DialogContent>
        </Dialog>
    )
}

export default StudentFormEditor
