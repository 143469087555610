import { ALLERGIESICON, BIRTHDAYICON, NOTEICON } from '../../../../../../styles/_icons'
import CustomSVG from '../../../../../Common/CustomSVG/CustomSVG'
import { IStudent } from '../../IStudent'
import './_ExtraDetails.scss'

interface IExtraDetailsProps {
    item: IStudent
}

const ExtraDetails = (props: IExtraDetailsProps) => {
    const { item } = props
    return (
        <>
            {item.AllergiesDSCR !== '' && typeof item.AllergiesDSCR !== 'undefined' && item.AllergiesDSCR !== null && (
                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={ALLERGIESICON} />
                    </div>
                    <div>{item.AllergiesDSCR}</div>
                </div>
            )}

            {item.BirthDate !== '1900/01/01' && item.BirthDate !== '' && (
                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={BIRTHDAYICON} />
                    </div>
                    <div>{item.BirthDate}</div>
                </div>
            )}

            {item.Note !== '' && (
                <div className="icon-container">
                    <div className="icon">
                        <CustomSVG strSVG={NOTEICON} />
                    </div>
                    <div>{item.Note}</div>
                </div>
            )}
        </>
    )
}
export default ExtraDetails
